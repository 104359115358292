import useFirm from 'hooks/useFirm';
import { Container } from 'react-bootstrap';
import Loading from 'components/Loading';
import { useAuth } from 'hooks/useAuth';
import FirmUserList from 'components/Firm/FirmUserList';
import { UserType } from 'briefpoint-client';

export default function FirmScreen() {
  const { firm } = useAuth()!;
  const [users, invites, , sendInvite, activateDeactivateUser] = useFirm(firm?.id);

  async function SubmitInvite(email: string, type: UserType) {
    await sendInvite(email, type);
  }

  async function ActivateDeactivateUser(userId: string, activate: boolean) {
    await activateDeactivateUser(userId, activate);
  }

  return (
    <Container className="page-container">
      <Loading isLoading={users === null}>
        {users ? (
          <FirmUserList
            invites={invites}
            users={users!}
            onInvite={SubmitInvite}
            onActivateDeactivate={ActivateDeactivateUser}
          />
        ) : (
          <p>No Users</p>
        )}
      </Loading>
    </Container>
  );
}
