/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Preferences
 */
export interface Preferences {
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    autoAddFirstObjection?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    includeGeneralObjections?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    hasSeenIntroTutorial?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    hasSeenSuggestBetaTutorial?: boolean;
}

export function PreferencesFromJSON(json: any): Preferences {
    return PreferencesFromJSONTyped(json, false);
}

export function PreferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Preferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'autoAddFirstObjection': !exists(json, 'autoAddFirstObjection') ? undefined : json['autoAddFirstObjection'],
        'includeGeneralObjections': !exists(json, 'includeGeneralObjections') ? undefined : json['includeGeneralObjections'],
        'hasSeenIntroTutorial': !exists(json, 'hasSeenIntroTutorial') ? undefined : json['hasSeenIntroTutorial'],
        'hasSeenSuggestBetaTutorial': !exists(json, 'hasSeenSuggestBetaTutorial') ? undefined : json['hasSeenSuggestBetaTutorial'],
    };
}

export function PreferencesToJSON(value?: Preferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'autoAddFirstObjection': value.autoAddFirstObjection,
        'includeGeneralObjections': value.includeGeneralObjections,
        'hasSeenIntroTutorial': value.hasSeenIntroTutorial,
        'hasSeenSuggestBetaTutorial': value.hasSeenSuggestBetaTutorial,
    };
}


