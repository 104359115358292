import { Form } from 'react-bootstrap';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import Button from './Button';
import { Popover, PopoverContent, PopoverTrigger } from "utils/popover";
import { ChevronDown } from 'react-bootstrap-icons';
import './DropDown.scss'
import { Jurisdiction } from 'briefpoint-client/models/Jurisdiction';

interface Props {
  text?: string;
  itemsList?: DropDownItem[];
  activeFilter: any[];
  updateFilter: Dispatch<SetStateAction<any[]>>;
}

export interface DropDownItem {
  default?: boolean;
  id: any;
  name: string;
}

export function DropDownRadio(props: Props) {
  const { itemsList, activeFilter, updateFilter } = props;
  const [toShow, setToShow] = useState<DropDownItem[]>([]);

  const clearFilters = () => {
    updateFilter(itemsList?.filter(ts => ts.default)?.map(itm => itm.id) ?? []);
  };

  useEffect(() => {
    setToShow(itemsList ?? []);
  }, [itemsList]);

  return (
    <Popover placement="bottom-start" modal={true}>
      {popperBtn(props.text ?? "")}
      <PopoverContent>
        <div className='popper-filter-dropdown'>
          <ul className="list-unstyled">
            {toShow?.map((item) => (
              <li key={item?.id ?? item.name}>
                <Form.Check
                  type='radio'
                  id={`radio-${item.id}`}
                  checked={activeFilter.some(filter => filter === item.id)}
                  onChange={() => updateFilter([item.id])}
                  label={item?.name}
                />
              </li>
            ))}
          </ul>
          {clearBtn(clearFilters)}
        </div>
      </PopoverContent>
    </Popover>
  );
}

export function DropDownFilterList(props: Props) {
  const { itemsList, activeFilter, updateFilter } = props;
  const [search, setSearch] = useState<string>('');
  const [toShow, setToShow] = useState<DropDownItem[]>([]);

  useEffect(() => {
    let toSet = itemsList?.filter(i => i.name.toLowerCase().includes(search.toLowerCase())) ?? [];
    setToShow(toSet);
  }, [itemsList, search]);

  const clearFilters = () => {
    updateFilter([]);
    setSearch("");
  };

  return (
    <Popover placement="bottom-start" modal={true}>
      {popperBtn(props.text ?? "")}
      <PopoverContent>
        <div className='popper-filter-dropdown' style={{ width: "230px" }}>
          <Form.Control
            className="mb-3"
            placeholder="Type to search..."
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <ul className="list-unstyled" style={{ overflow: "auto", maxHeight: "230px" }}>
            {toShow?.map((item) => (
              <li key={item?.id ?? item.name}
                onClick={() => {
                  const idx = activeFilter.find(it => it === item.id);
                  if (idx === undefined) {
                    updateFilter([...activeFilter, item.id]);
                  }
                  else {
                    updateFilter(activeFilter.filter(fi => fi !== item.id))
                  }
                }}>
                <Form.Check
                  type="checkbox"
                  readOnly={true}
                  checked={activeFilter.some(filter => filter === item.id)}
                  label={item?.name}
                />
              </li>
            ))}
          </ul>
          {clearBtn(clearFilters)}
        </div>
      </PopoverContent>
    </Popover>
  );
}

function clearBtn(clearFilters: () => void) {
  return <div style={{ display: 'flex' }}>
    <div style={{ flex: 1, alignContent: "flex-start" }}>
      <Button
        className="btn-dropdown-cl btn-sm"
        onClick={clearFilters}>
        Clear
      </Button>
    </div>
  </div>;
}

export interface hasTagId {
  tagIds?:  number[] | null | undefined;
}

export function getTagsInUse(tg: hasTagId[], allTags: Map<number, string> | undefined) {
  let tags: Number[] = [];
  tg.forEach(tq => {
    tq.tagIds?.forEach(tgId => {
      if (!tags.includes(tgId)) {
        tags.push(tgId);
      }
    })
  })
  return Array.from(allTags?.entries() ?? []).filter(at => tags.includes(at[0]));
}

export function getMiscDisplay(active: any[], msc:any[]) {
  let ret = msc.find(x => x.id === active[0])?.name;

  if (active.length > 1) {
    ret += ` (+${active.length - 1})`;
  }
  return ret;
}

export function getCaseTypeDisplay(active: any[], jx:Jurisdiction) {
  let ret = getCases(jx).find(x => x.id === active[0])?.name;

  if (active.length > 1) {
    ret += ` (+${active.length - 1})`;
  }
  return ret;
}

export function getTagsDisplay(active: any[], tags:Map<number, string> | undefined ) {
  let ret = tags!.get(active[0]);

  if (active.length > 1) {
    ret += ` (+${active.length - 1})`;
  }

  return ret;
}

export function getSortedTags(hasTag:hasTagId[] , availableTags: Map<number, string> | undefined, tagFilter:any[]) {
  if (!availableTags) {
    return [];
  }

  const tagsInUse = getTagsInUse(hasTag ?? [], availableTags);

  return tagsInUse.sort((a, b) => {
    const aSelected = tagFilter?.includes(a[0]);
    const bSelected = tagFilter?.includes(b[0]);

    if (aSelected && !bSelected) {
      return -1;
    }

    if (bSelected && !aSelected) {
      return 1;
    }

    return a[1].localeCompare(b[1]);
  }).map(tg => ({ name: tg[1] ?? '', id: tg[0] ?? '' }));
}

export interface hasCaseTypeId {
  caseTypeId?:  string | null | undefined;
}

export function getCaseTypesInUse(cs: hasCaseTypeId[], jx: Jurisdiction) {
  let caseId: (string | undefined)[] = [];
  cs.forEach(tq => {
    if (!caseId.includes(tq.caseTypeId ?? undefined)) {
      caseId.push(tq.caseTypeId ?? undefined);
    }
  })
  return getCases(jx).filter(c => caseId.includes(c.id));
}

export function getCases(jurisdiction: Jurisdiction) {
  const cases = jurisdiction?.caseClasses?.filter(c => c.subTypes).flatMap(c => [c.subTypes!]).flat()?.map(cc => ({ name: cc.shortName ?? '', id: cc.id ?? '' })) ?? [];
  const extra = jurisdiction?.caseClasses?.map(c => ({ name: c.name ?? '', id: c.id ?? '' })) ?? [];
  return [...extra, ...cases];
}

export function getSortedCases(allVariants: hasCaseTypeId[], ca_superior_court: Jurisdiction, caseFilter?: any[]) {
  const sortedCases = getCaseTypesInUse(allVariants ?? [], ca_superior_court).sort((a, b) => {
    const aSelected = caseFilter?.includes(a.id);
    const bSelected = caseFilter?.includes(b.id);

    if (aSelected && !bSelected) {
      return -1;
    }

    if (bSelected && !aSelected) {
      return 1;
    }

    return a.name.localeCompare(b.name);
  }).map(cc => ({ name: cc.name ?? "", id: cc.id })) ?? [];

  return sortedCases;
}

function popperBtn(text: string) {
  return <PopoverTrigger style={{ border: 'none', background: 'none' }}>
    <div className="btn btn-outline-primary btn-sm">
      {text}&nbsp;
      <ChevronDown />
    </div>
  </PopoverTrigger>;
}
