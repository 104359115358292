import {
  Case,
  CaseMetadata,
  Client,
  CollectionItem,
  CreateDiscoveryRequest,
  DocumentStatus,
  DocumentSubType,
  DocumentType,
  ExternalCase,
  ExternalCaseIEnumerableExternalPartnerResponse,
  ExternalClient,
  ExternalClientIEnumerableExternalPartnerResponse,
  PartyPosition,
  PropoundingParty,
  Respondent,
  ServiceManner,
} from "briefpoint-client";
import { Jurisdiction } from "briefpoint-client/models/Jurisdiction";
import { capitalize } from "lodash";
import Button from "components/Button";
import { FormPreventDefault } from "components/Form";
import { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { DocumentDetailClient } from "services/DocumentService";
import { PopupNavigator } from "./../ExternalPartner/AuthPopup/PopupNavigator";
import { UrlUtils } from "./../ExternalPartner/AuthPopup/UrlUtils";
import PdfViewer from "components/Wizard/PdfViewer";
import SourceDocumentReviewSteps from "components/Wizard/SourceDocumentReviewSteps";
import { useCaseApi, useClientApi, useFirmApi, useUserApi, useDocumentApi } from "hooks/useApi";
import { useAuth } from "hooks/useAuth";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { GetEnumValues } from "hooks/useDocuments";
import ReviewPageOne from "./ReviewPageOne";
import ReviewPageTwo from "./ReviewPageTwo";
import ReviewPageThree from "./ReviewPageThree";
import { GetExternalPartnerAuthUrl } from "components/ExternalPartner/ExternalPartnerConnection";
import rg4js from 'raygun4js';
import { externalRequestWrapper } from "utils/ExternalPartner/utils";
import { handleExternalReqResCodes } from "utils/ExternalPartner/utils";
import useConfig, { COMPLAINTS } from "hooks/useConfig";
import { remapExternalClientList } from "./utils";
import ReviewPageOnePd from "./ReviewPageOnePd";
import ReviewPageTwoPd from "./ReviewPageTwoPd";
import ReviewPageThreePd from "./ReviewPageThreePd";
import ReviewPageFourPd from "./ReviewPageFourPd";
import { Link } from "react-router-dom";
import styles from "./SourceDocumentReview.module.scss"

export const CaliforniaId = "7c635e83-3d84-4b75-a09f-7acbe6c07bf8";
export const blankId = "00000000-0000-0000-0000-000000000000";

export default function SourceDocumentReview({
  document,
  updateDocument,
  jurisdictions,
}: {
  document: DocumentDetailClient;
  updateDocument: (document: DocumentDetailClient) => Promise<void>;
  jurisdictions: Jurisdiction[];
}) {
  const { user, setUser, firm } = useAuth()!;
  const [showEdit, setShowEdit] = useState(!document.caseId);
  // const [setActiveRef, setActiveLineRef, setInformationRef] = usePositionActive();
  const [sections] = useState<string[]>(document.info?.documentType === DocumentType.Pleading ? ["page1Pd", "page2Pd", "page3Pd"] : ["page1", "page2", "page3"]);
  const [section, setSection] = useState(document.info?.documentType === DocumentType.Pleading ? "page1Pd" : "page1");
  const [judge, setJudge] = useState(document.info?.judge || "");
  const [_case, setCase] = useState<Case | undefined>();
  const [cacheNewCase, setCacheNewCase] = useState<Case | undefined>();
  const [client, setClient] = useState<Client | undefined>();
  const [cases, setCases] = useState<Case[] | undefined>();
  const [externalCases, setExternalCases] = useState<ExternalCase[] | undefined>();
  const [clients, setClients] = useState<Client[] | undefined>();
  const [externalClients, setExternalClients] = useState<ExternalClient[] | undefined>();
  // const [filedDate, setFiledDate] = useState(document.info?.filedDate || undefined);
  const [confirmedSections, setConfirmedSections] = useState<string[]>([]);
  const [venue, setVenue] = useState(document.info?.venue || "");
  const [caseNumber, setCaseNumber] = useState(document.info?.caseNumber || "");
  const [caseMetaData, setCaseMetaData] = useState<CaseMetadata | undefined>(document.info?.caseMetadata || undefined);
  const [selectedRequestTypes, setSelectedRequestTypes] = useState<DocumentSubType[]>([]);
  const [generatingRequests, setGeneratingRequests] = useState(false);
  const [documentType] = useState(
    document.info?.documentType || DocumentType.Unknown,
  );
  const [documentSubType, setDocumentSubType] = useState(
    document.info?.documentSubType || DocumentSubType.Unknown,
  );
  const [propoundingParty, setPropoundingParty] = useState(
    document.info?.propoundingParty,
  );
  const [clientIsDefendant, setClientIsDefendant] = useState(
    _case?.clientPosition === PartyPosition.Defendant
  );
  const [respondingParty, setRespondingParty] = useState(
    document.info?.respondingParty,
  );
  const [propoundingPartyName, setPropoundingPartyName] = useState(
    document.info?.propoundingPartyName || "",
  );
  const [respondingPartyName, setRespondingPartyName] = useState(
    document.info?.respondingPartyName || "",
  );
  const [isCrossComplaint, setIsCrossComplaint] = useState(
    document.info?.isCrossComplaint || false,
  );
  const [respondent, setRespondent] = useState(
    document.info?.respondent || Respondent.Respondent,
  );
  const [respondentCustom, setRespondentCustom] = useState(
    document.info?.respondentCustom || formatRespondentCustom(),
  );
  const [setNumber, setSetNumber] = useState(document.info?.setNumber || 1);
  const [saving, setSaving] = useState(false);
  const [isExternalCasesLoading, setIsExternalCasesLoading] = useState<boolean>(
    false,
  );
  const [startingNumber, setStartingNumber] = useState(
    document.info?.startingNumber || 1,
  );
  const [dateOfService, setDateOfService] = useState<string | undefined>(
    document.info?.dateOfService || undefined,
  );
  const [methodOfService, setMethodOfService] = useState<
    ServiceManner | undefined
  >(document.info?.serviceManner);
  const [dueDate, setDueDate] = useState<string | undefined>(
    document.info?.dueDate || undefined,
  );
  const [formError, setFormError] = useState("");
  const history = useHistory();
  const caseApi = useCaseApi();
  const clientApi = useClientApi();
  const firmApi = useFirmApi();
  const userApi = useUserApi();
  const documentApi = useDocumentApi();
  const [config] = useConfig();
  const originalClient = useRef<Client | undefined>();
  const originalCase = useRef<Case | undefined>();
  // FOR PLEADINGS
  const [isPleadingDocsPolling, setIsPleadingDocsPolling] = useState<boolean>(false);
  const [caseType, setCaseType] = useState<string | null | undefined>(document.info?.caseMetadata?.caseType?.id ?? '');
  const [genericInfo, setGenericInfo] = useState<{ [key: string]: string; } | null | undefined>(document.info?.caseMetadata?.genericInfo);
  const [pleadClientPos, setPleadClientPos] = useState<{ name: string | undefined, clientPos: PropoundingParty | undefined } | undefined>({ name: document.info?.propoundingPartyName ?? undefined, clientPos: document.info?.propoundingParty ?? undefined });
  const [timelineDetails, setTimelineDetails] = useState<string | null | undefined>(document.info?.caseMetadata?.timeline ?? '');
  const [damages, setDamages] = useState<string | null | undefined>(document.info?.caseMetadata?.damages || '');
  const [incidentDate, setIncidentDate] = useState<string | null | undefined>(document.info?.caseMetadata?.incidentDate ?? '');
  const [incidentDescription, setIncidentDescription] = useState<string | null | undefined>(document.info?.caseMetadata?.incidentDescription ?? '');
  const [incidentLocation, setIncidentLocation] = useState<string | null | undefined>(document.info?.caseMetadata?.incidentLocation ?? '');
  const [incidentTime, setIncidentTime] = useState<string | null | undefined>(document.info?.caseMetadata?.incidentTime ?? '');
  const [allegations, setAllegations] = useState<Array<CollectionItem> | null>(document.info?.caseMetadata?.allegations ?? null);
  const [injuries, setInjuries] = useState<Array<CollectionItem> | null>(document.info?.caseMetadata?.injuries ?? null);
  const [causesOfAction, setCausesOfAction] = useState<Array<CollectionItem> | null>(document.info?.caseMetadata?.causesOfAction ?? [{ number: 1, text: '' }]);
  const [agreements, setAgreements] = useState<Array<CollectionItem> | null>(document.info?.caseMetadata?.agreements ?? null);
  const [, featureFlags] = useConfig();
  const useComplaints = featureFlags()[COMPLAINTS] ? featureFlags()[COMPLAINTS](undefined, undefined) : false;

  // Messages for requests errors
  const ERROR_MSG = {
    GENERAL: "An error occurred. Please try again later.",
    EXTERNAL_PARTNER: {
      GENERAL: `There was an error connecting to ${_case?.integration?.partner}. Please try again later.`,
      FAILED_CLIENT_FETCH: `There was an error retrieving external client data from ${_case?.integration?.partner}.`,
      FAILED_CASE_FETCH: `There was an error retrieving external client data from ${_case?.integration?.partner}.`,
      FAILED_CASE_CREATION: `Unable to create a new case.`,
      FAILED_CASE_CLIENT_FETCH: `Unable to retrieve external case clients for current case from ${_case?.integration?.partner}.`,
      FAILED_CASE_CLIENT_EXTERNAL: `Unable to create external client for current case from ${_case?.integration?.partner}.`,
      FAILED_CASE_JURISDICTION_VENUE_SET: `Unable to set external case's jurisdiction/venue from ${_case?.integration?.partner}.`,
      USER_CANCELLED: "Authentication cancelled"
    },
    PLEADINGS: {
      FAIL: 'There was an error checking the status of generated requests.'
    }
  };

  const completedSteps = new Set(
    confirmedSections.flatMap<number>((_, index) =>
      Object.keys(confirmedSections).length ? [index] : []
    ),
  );

  const initiateAuth = useCallback(async () => {
    if (!user || !firm) {
      return null;
    }
    const firmProviders = firm.preferences?.enabledExternalProviders?.values();
    let enabledPartners = firmProviders ? Array.from(firmProviders) : null;

    if (!enabledPartners?.length) {
      return null;
    }

    const partner = enabledPartners[0];
    const authUrl = GetExternalPartnerAuthUrl(partner, user?.id!, config);
    const popupNavigator = new PopupNavigator();
    const handle = await popupNavigator.prepare({});

    try {
      const result = await handle.navigate({
        url: authUrl!,
      });

      const params = UrlUtils.readParams(result.url);
      const code = params.get("code");
      if (code) {
        const tokenResult = await userApi.userExternalPartnerAuth({
          userId: user.id!,
          code,
          partner: partner,
        });
        if (tokenResult.responseCode !== 200) {
          throw new Error(`Unable to authenticate to ${partner}. Status Code: ${tokenResult.responseCode}`);
        }

        setUser((u) => {
          const newUser = { ...u };
          newUser.externalConnection = { partner: partner, isActive: true };
          return newUser;
        });

        // If the user reAuthenticates from a 401 response, fetch external cases to display in the selectable case list
        if (!externalCases) {
          const res = await caseApi.caseGetExternal({ firmId: user.firmId! });

          if (res) {
            if (res.responseCode === 200) {
              setExternalCases(res.data ?? undefined);
            } else {
              setFormError(ERROR_MSG.GENERAL);
              rg4js('send', {
                error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_FETCH),
              });
            }
          }
        }

      } else {
        setSaving(false);
        throw new Error(`Unexpected response from auth: ${params}`);
      }
      setSaving(false);
    } catch (_e) {
      let message = "";
      if (typeof _e === "string") {
        message = _e;
      } else if (_e instanceof Error) {
        message = _e.message;
      }

      if (message.includes("closed by user")) {
        setFormError(ERROR_MSG.EXTERNAL_PARTNER.USER_CANCELLED);
        throw new Error(ERROR_MSG.EXTERNAL_PARTNER.USER_CANCELLED);
      }
      setSaving(false);
    }
  }, [user, firm, caseApi, userApi, externalCases, setUser, setFormError, ERROR_MSG.GENERAL, ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_FETCH, ERROR_MSG.EXTERNAL_PARTNER.USER_CANCELLED, config]);

  useEffect(() => {
    async function load() {
      if (user) {
        if (document.clientId) {
          const [docClient, docCase] = await Promise.all([
            await clientApi.clientGet({
              firmId: user.firmId!,
              id: document.clientId!,
            }),
            document.caseId
              ? await caseApi.caseGet({
                firmId: user.firmId!,
                id: document.caseId!,
              })
              : Promise.resolve(),
          ]);
          originalClient.current = docClient;
          setClient(docClient);

          if (docCase) {
            originalCase.current = docCase;
            setCase(docCase);
            if (!document?.info?.venue && docCase.venue) {
              setVenue(docCase.venue);
            }
          }
        }
      }
    }
    load();
  }, [caseApi, clientApi, user, document]);

  const handleExternalCaseSet = useCallback((fetchedExternalCases: void | ExternalCaseIEnumerableExternalPartnerResponse) => {
    if (fetchedExternalCases) {
      if (fetchedExternalCases.responseCode === 200) {
        setExternalCases(fetchedExternalCases.data ?? []);
      } else {
        if (fetchedExternalCases.responseCode === 401) {
          // initiate re-auth
          initiateAuth();
        } else {
          // show some sort of alert
          setFormError(ERROR_MSG.GENERAL);
          rg4js('send', {
            error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_FETCH),
          });
        }
      }
      setIsExternalCasesLoading(false);
    }
  }, [ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_FETCH, ERROR_MSG.GENERAL, initiateAuth]);

  useEffect(() => {

    async function load() {
      if (user && !cases && !clients) {
        const [allCases, allClients] = await Promise.all(
          [
            firmApi.firmGetFirmCases({ firmId: user.firmId! }),
            clientApi.clientGetAll({ firmId: user.firmId! })
          ],
        );

        if (
          document.caseId || !document.info?.plaintiffs ||
          !document.info?.defendants
        ) {
          setCases(allCases);
        } else {
          const st = `${document.info.plaintiffs.split(",")[0]} vs ${document.info.defendants.split(",")[0]
            }`;
          if (!allCases.find((c) => c.shortTitle === st)) {
            const c = { shortTitle: st };
            setCases([...allCases, c]);
            setCase(c);
          } else {
            setCases(allCases);
          }
        }

        const nameToUse = (document.info?.documentType === DocumentType.Pleading && document.info?.propoundingPartyName) ? document.info?.propoundingPartyName : document.info?.respondingPartyName;
        if (document.clientId || !nameToUse) {
          setClients(allClients);
        } else {
          if (
            !allClients.find((c) =>
              c.name === nameToUse
            )
          ) {
            const c = { name: nameToUse };
            setClients([...allClients, c]);
            setClient(c);
          } else {
            setClients(allClients);
          }
        }

        if (!document.clientId && document.caseId) {
          const _case = allCases.find((x) => x.id === document.caseId);
          if (_case) {
            setCase(_case);
          }
        }

        if (
          !document.clientId && !document.caseId && document.info?.caseNumber
        ) {
          const _case = allCases.find((x) =>
            x.caseNumber === document.info?.caseNumber
          );

          if (_case) {
            setCase(_case);
            const client = allClients.find((x) => x.id === _case.clientId);
            setClient(client);
          }
        }
      }
    }
    load();
  }, [firmApi, userApi, clientApi, caseApi, user, setUser, firm, cases, clients, document, ERROR_MSG.GENERAL]);

  useEffect(() => {
    async function load() {
      // Update the externalCases after a successful connection w/ a partner
      if ((user && cases && clients) && user?.externalConnection?.isActive && !externalCases) {
        const tempExternalCases = await caseApi.caseGetExternal({ firmId: user.firmId! });
        handleExternalCaseSet(tempExternalCases);
      }
    }

    load();
  }, [caseApi, cases, clients, externalCases, handleExternalCaseSet, user]);

  useEffect(() => {
    let isMounted = true;

    const abortController = new AbortController();
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    async function pollDocuments() {
      while (isMounted && isPleadingDocsPolling) {
        try {
          await delay(2000);
          const results = await documentApi.documentGetList({ parentDocumentId: document.id, source: 9 }, { signal: abortController.signal });
          const ready = results.find(x => x.status === DocumentStatus.Processed);
          if (ready && isMounted) {
            history.push(`/wizard/propound/${document.id}`);
            setIsPleadingDocsPolling(false);
          }
        } catch (error: any) {
          if (error.name === 'AbortError') {
            // Abort polling when moving away to a different view
            console.log(error);
          } else {
            rg4js('send', { error: new Error(ERROR_MSG.PLEADINGS.FAIL), });
            throw new Error(ERROR_MSG.PLEADINGS.FAIL);
          }
          setIsPleadingDocsPolling(false);
        }
      }
    }

    if (generatingRequests) {
      setIsPleadingDocsPolling(true);
      pollDocuments();
    }

    return () => {
      isMounted = false;
      abortController.abort();
      setIsPleadingDocsPolling(false);
    };
  }, [generatingRequests, documentApi, document.id, history, isPleadingDocsPolling, ERROR_MSG.PLEADINGS.FAIL]);

  const handleFilterChange = useCallback(async (filter?: string) => {
    if (user?.externalConnection?.isActive) {
      setIsExternalCasesLoading(true);
      const getExternalCases = await caseApi.caseGetExternal({
        firmId: user.firmId!, filter
      });
      handleExternalCaseSet(getExternalCases);
      setIsExternalCasesLoading(false);
    }
  }, [caseApi, handleExternalCaseSet, user]);

  function updateRespondent(respondent: Respondent) {
    if (respondent === Respondent.Custom) {
      setRespondentCustom(
        document.info?.respondentCustom || formatRespondentCustom(),
      );
    }

    setRespondent(respondent);
  }

  function formatRespondentCustom() {
    var excludedList = ["et", "al", "and", "with"];

    return client?.respondentName || client?.name ||
      (document.info?.respondingPartyName || "").split(" ").map((value) => {
        if (excludedList.includes(value)) {
          return value.toLowerCase();
        }
        return capitalize(value);
      }).join(" ");
  }

  function jumpTo(stepIndex: number) {
    if (documentType === DocumentType.Pleading) {
      if (confirmedSections.length > stepIndex) {
        if (stepIndex === 0) {
          setSection("page1Pd");
          setConfirmedSections([]);
        } else if (stepIndex === 1) {
          setSection("page2Pd");
          setConfirmedSections(["page1Pd"]);
        } else if (stepIndex === 2) {
          setSection("page3Pd");
          setConfirmedSections(["page1Pd", "page2Pd"]);
        }
      }
    } else {
      if (confirmedSections.length > stepIndex) {
        if (stepIndex === 0) {
          setSection("page1");
          setConfirmedSections([]);
        } else if (stepIndex === 1) {
          setSection("page2");
          setConfirmedSections(["page1"]);
        } else if (stepIndex === 2) {
          setSection("page3");
          setConfirmedSections(["page1", "page2"]);
        }
      }
    }
  }

  function cancelShowEdit() {
    // Use cached case/client data in case the user removes case info on step 1
    if (originalClient.current) {
      setClient(originalClient.current);
      setCase(originalCase.current);
    }
    setShowEdit(false);
  }

  const SectionComponent = {
    page1: ReviewPageOne,
    page1Pd: ReviewPageOnePd,
    page2: ReviewPageTwo,
    page2Pd: ReviewPageTwoPd,
    // type: DocumentTypeComponent,
    page3: ReviewPageThree,
    page3Pd: ReviewPageThreePd,
    page4Pd: ReviewPageFourPd,
  }[section]!;

  async function handleUpdateDocument() {
    setFormError("");
    let success = true;
    try {
      await updateDocument(document);
    } catch (error) {
      setFormError(ERROR_MSG.GENERAL);
      success = false;
    }
    setSaving(false);
    return success;
  }

  async function sdrUiFlow() {
    if (section === "page1") {
      if (!venue) {
        setFormError("Please select a Venue");
        setSaving(false);
        return;
      }

      if (!_case) {
        setFormError("Please select or create a Case");
        setSaving(false);
        return;
      }

      let tempCase = _case!;
      let tempClient = client!;
      // Cache the results of new a new case/external case to avoid case creation duplication 
      // fetching external clients from smokeball times out
      let newCase = cacheNewCase;

      function exClientFetchSuccess(res: ExternalClientIEnumerableExternalPartnerResponse) {
        let matchedNameIndex;
        const remappedData = remapExternalClientList(res);

        matchedNameIndex = remappedData?.findIndex(val => val.name === document.info?.respondingPartyName);
        setExternalClients(remappedData ?? undefined);

        if (remappedData && remappedData.length > 0) {
          setClient(matchedNameIndex !== -1 ? remappedData[matchedNameIndex!] : remappedData[0]);
        }
      }

      function exClientFetchError() {
        setFormError(ERROR_MSG.EXTERNAL_PARTNER.GENERAL);
        tempClient.name = "";
        rg4js('send', {
          error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CLIENT_FETCH),
        });
        setSaving(false);
        throw new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_CLIENT_FETCH);
      }

      // Creating a new case
      async function fetchExternalClients() {
        const res = await externalRequestWrapper(clientApi.clientGetExternal({ firmId: user?.firmId!, externalCaseId: tempCase.integration?.identifier! }), 3);
        handleExternalReqResCodes(res, exClientFetchSuccess, exClientFetchError, initiateAuth);
      }

      if (!_case?.id) {
        tempCase.jurisdiction = document.info?.jurisdiction ?? CaliforniaId;
        tempCase.venue = venue;
        tempCase.caseNumber = caseNumber;
        tempCase.judge = judge;
        tempCase.title ??= tempCase.shortTitle;
        tempCase.plaintiffs = document.info?.plaintiffs;
        tempCase.defendants = document.info?.defendants;

        try {
          // Check if we have a cached new case in case external client retrieval failed and the user is re-submitting...
          if (!cacheNewCase) {
            newCase = await caseApi.casePost({
              firmId: user!.firmId!,
              _case: tempCase,
            });
            setCacheNewCase(newCase);
          }
          // Create a new external case
          if (tempCase.integration?.identifier) {
            if (!cacheNewCase) {
              await caseApi.caseExternalIntegration({
                firmId: user!.firmId!,
                id: newCase?.id!,
                externalIntegration: tempCase.integration,
              });
            }
            newCase!.integration = tempCase.integration;
            // Get external clients
            try {
              await fetchExternalClients();
            } catch (error) {
              setFormError(ERROR_MSG.EXTERNAL_PARTNER.GENERAL);
              rg4js('send', {
                error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CLIENT_FETCH),
              });
              setSaving(false);
              return;
            }
          }

          if (tempClient) {
            tempClient.id = undefined;
          }

          tempCase = newCase!;
          document.clientId = null;
        } catch (error) {
          setFormError(ERROR_MSG.GENERAL);
          rg4js('send', {
            error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_CREATION + `Data: {${error}}`),
          });
          setSaving(false);
          return;
        }
      } else {
        // If the current case already exists
        if (_case.integration?.identifier) {
          try {
            if (_case.jurisdiction === blankId || _case.venue === blankId) {
              tempCase.jurisdiction = document.info?.jurisdiction ?? CaliforniaId;
              tempCase.venue = venue;
              try {
                await caseApi.casePut({ firmId: user!.firmId!, id: tempCase.id!, _case: tempCase })
              } catch (error) {
                setFormError(ERROR_MSG.GENERAL);
                rg4js('send', {
                  error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_JURISDICTION_VENUE_SET + ` Data: {${error}}`),
                });
                setSaving(false);
              }
            }
            const res = await externalRequestWrapper(clientApi.clientGetExternal({ firmId: user?.firmId!, externalCaseId: tempCase.integration?.identifier! }), 3);
            handleExternalReqResCodes(res, exClientFetchSuccess, exClientFetchError, initiateAuth);
          } catch (error) {
            setFormError(ERROR_MSG.GENERAL);
            rg4js('send', {
              error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_CLIENT_FETCH + ` Data: {${error}}`),
            });
            setSaving(false);
            return;
          }
        }

        if ((!_case.caseNumber && caseNumber) || (!_case.judge && judge)) {
          tempCase.caseNumber ??= caseNumber;
          tempCase.judge ??= judge;
          try {
            await caseApi.casePut({
              firmId: user!.firmId!,
              id: tempCase.id!,
              _case: tempCase,
            });
          } catch (error) {
            setFormError(ERROR_MSG.GENERAL);
            setSaving(false);
            return;
          }
        }
        document.clientId = tempCase.clientId;
      }

      document.caseId = tempCase.id;
      document.info!.venue = venue;
      if (await handleUpdateDocument()) {
        setCase(tempCase);
        setSection(documentType === DocumentType.Pleading ? "page2Pd" : "page2");
        setConfirmedSections(["page1"]);
      }
    } else if (section === "page2") {
      const subTypeChanged = document.info!.documentSubType !== documentSubType;
      document.info!.documentSubType = documentSubType;
      document.info!.setNumber = setNumber;
      document.info!.startingNumber = startingNumber;
      document.info!.dateOfService = dateOfService?.length === 0 ? null : dateOfService;
      document.info!.serviceManner = methodOfService;
      document.info!.dueDate = dueDate;
      if (await handleUpdateDocument()) {
        // If they changed the subtype, send them back to document list for re-parsing
        if (subTypeChanged) {
          history.goBack();
          setSaving(false);
        }

        setSection("page3");
        setConfirmedSections(["page1", "page2"]);
        // }
      }
    } else if (section === "type") {
      document.info!.documentType = documentType;
      if (documentType === DocumentType.Pleading) {
        document.status = DocumentStatus.InfoConfirmed;
        if (await handleUpdateDocument()) {
          history.push(`/wizard/${document.id}`);
        }
      } else {
        if (await handleUpdateDocument()) {
          setSection("page3");
          // setConfirmedSections(['page1', 'page2', 'type']);
          setConfirmedSections(["page1", "page2", "page3"]);
        }
      }
    }
    else {
      if (!client) {
        setFormError("Please select or create a Client");
        setSaving(false);
        return;
      }

      if (propoundingParty === PropoundingParty.Unknown) {
        setFormError("Please select the Propounding Party Type");
        setSaving(false);
        return;
      }

      if (respondingParty === PropoundingParty.Unknown) {
        setFormError("Please select the Responding Party Type");
        return;
      }

      let tempClient = client!;
      let tempCase = _case!;

      // Creating a new client
      if (!client?.id) {
        if (
          client && document.info?.respondingPartyName &&
          client.name !== document.info?.respondingPartyName
        ) {
          client.aliases = document.info?.respondingPartyName;
        }

        try {
          const newClient = await clientApi.clientPost({
            firmId: user!.firmId!,
            client: client,
          });
          tempClient = newClient;
        } catch (error) {
          setFormError(ERROR_MSG.GENERAL);
          setSaving(false);
          return;
        }

        if (client?.integration?.identifier) {
          try {
            await clientApi.clientExternalIntegration({ firmId: user!.firmId!, id: tempClient.id!, externalIntegration: tempClient?.integration })
          } catch (error) {
            setFormError(ERROR_MSG.GENERAL);
            setSaving(false);
            rg4js('send', {
              error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_CLIENT_EXTERNAL),
            });
            throw new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_CLIENT_EXTERNAL);
          }
        }

      }

      // If there is no document.clientId, we know this is a new client for this case. So update the case with a client id as well as the document's client id.
      if (!document.clientId) {
        document.clientId = tempClient.id;
        tempCase.clientId = tempClient.id;
      }

      // add client to case
      try {
        await caseApi.caseAddClient({
          firmId: user!.firmId!,
          id: tempCase.id!,
          body: tempClient.id!,
        });
      } catch (error) {
        setFormError(ERROR_MSG.GENERAL);
        setSaving(false);
        return;
      }

      document.info!.documentSubType = documentSubType;
      document.info!.propoundingParty = propoundingParty ||
        PropoundingParty.Unknown;
      document.info!.respondingParty = respondingParty;
      document.info!.propoundingPartyName = propoundingPartyName;
      document.info!.isCrossComplaint = isCrossComplaint;
      document.info!.respondent = respondent;
      document.info!.respondingPartyName = respondingPartyName;
      document.info!.respondentCustom = respondent === Respondent.Custom
        ? respondentCustom
        : "";

      // If they've changed the respondentCustom and the Client doesn't already have a respondent name, add it automatically
      if (
        document.info!.respondentCustom &&
        tempClient!.name !== document.info!.respondentCustom &&
        !tempClient!.respondentName
      ) {
        tempClient!.respondentName = document.info!.respondentCustom;
        try {
          await clientApi.clientPut({
            firmId: user!.firmId!,
            id: tempClient!.id!,
            client: tempClient,
          });
        } catch (error) {
          setFormError(ERROR_MSG.GENERAL);
          setSaving(false);
          return;
        }
      }

      if (
        (!tempCase!.clientPosition ||
          tempCase!.clientPosition === PartyPosition.Unknown) &&
        document.info!.respondingParty
      ) {
        // need to do this jazz because UserService enums are being returned as strings not numbers like the DocumentService,
        // probably need to change UserService to behave like DocumentService but there are too many cases to handle right now
        tempCase!.clientPosition = GetEnumValues(document.info!.respondingParty)
          .join(", ") as PartyPosition;
        try {
          await caseApi.casePut({
            firmId: user!.firmId!,
            id: tempCase!.id!,
            _case: tempCase,
          });
        } catch (error) {
          setFormError(ERROR_MSG.GENERAL);
          setSaving(false);
          return;
        }
      }

      if (!tempCase?.otherParties?.length) {
        try {
          await caseApi.caseCreateParty({
            firmId: user!.firmId!,
            id: tempCase!.id!,
            party: {
              name: propoundingPartyName,
              position: GetEnumValues(document.info!.propoundingParty).join(
                ", ",
              ) as PartyPosition,
            },
          });
        } catch (error) {
          setFormError(ERROR_MSG.GENERAL);
          setSaving(false);
          return;
        }
      }

      document.status = DocumentStatus.InfoConfirmed;
      if (await handleUpdateDocument()) {
        setCase(tempCase);
        setClient(tempClient);
        history.push(`/wizard/${document.id}`);
      }
      // }
    }

  }

  async function sdrPleadingUiFlow() {
    if (section === "page1Pd") {
      if (!venue) {
        setFormError("Please select a Venue");
        setSaving(false);
        return;
      }

      if (!_case) {
        setFormError("Please select or create a Case");
        setSaving(false);
        return;
      }

      if (!propoundingPartyName || !client) {
        setFormError("Please select a Propounding Party");
        setSaving(false);
        return;
      }

      if (!respondingPartyName) {
        setFormError("Please select a Responding Party");
        setSaving(false);
        return;
      }

      let tempCase = _case!;
      let tempClient = client!;
      // Cache the results of new a new case/external case to avoid case creation duplication 
      // fetching external clients from smokeball times out
      let newCase = cacheNewCase;

      let setDefendantName = (clientIsDefendant && respondingParty === PropoundingParty.Defendant) || (!clientIsDefendant && respondingParty === PropoundingParty.Plaintiff) ? propoundingPartyName : respondingPartyName;

      let setPlaintiffName = !((clientIsDefendant && respondingParty === PropoundingParty.Defendant) || (!clientIsDefendant && respondingParty === PropoundingParty.Plaintiff)) ? propoundingPartyName : respondingPartyName;

      document.info!.documentSubType = documentSubType;
      document.info!.propoundingParty = propoundingParty || PropoundingParty.Unknown;
      document.info!.respondingParty = respondingParty;
      document.info!.propoundingPartyName = propoundingPartyName;
      document.info!.isCrossComplaint = isCrossComplaint;
      document.info!.respondent = respondent;
      document.info!.respondingPartyName = respondingPartyName;
      document.info!.respondentCustom = respondent === Respondent.Custom ? respondentCustom : "";
      tempCase.defendants = setDefendantName;
      tempCase.plaintiffs = setPlaintiffName;
      tempCase.clientPosition = clientIsDefendant ? PartyPosition.Defendant : PartyPosition.Plaintiff;

      function exClientFetchSuccess(res: ExternalClientIEnumerableExternalPartnerResponse) {
        let matchedNameIndex;
        const remappedData = remapExternalClientList(res);

        matchedNameIndex = remappedData?.findIndex(val => val.name === document.info?.respondingPartyName);
        setExternalClients(remappedData ?? undefined);

        if (remappedData && remappedData.length > 0) {
          setClient(matchedNameIndex !== -1 ? remappedData[matchedNameIndex!] : remappedData[0]);
        }
      }

      function exClientFetchError() {
        setFormError(ERROR_MSG.EXTERNAL_PARTNER.GENERAL);
        tempClient.name = "";
        rg4js('send', {
          error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CLIENT_FETCH),
        });
        setSaving(false);
        throw new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_CLIENT_FETCH);
      }

      // Creating a new case
      async function fetchExternalClients() {
        const res = await externalRequestWrapper(clientApi.clientGetExternal({ firmId: user?.firmId!, externalCaseId: tempCase.integration?.identifier! }), 3);
        handleExternalReqResCodes(res, exClientFetchSuccess, exClientFetchError, initiateAuth);
      }

      if (!_case?.id) {
        tempCase.jurisdiction = document.info?.jurisdiction ?? CaliforniaId;
        tempCase.venue = venue;
        tempCase.caseNumber = caseNumber;
        tempCase.judge = judge;
        tempCase.title ??= tempCase.shortTitle;

        try {
          // Check if we have a cached new case in case external client retrieval failed and the user is re-submitting...
          if (!cacheNewCase) {
            newCase = await caseApi.casePost({
              firmId: user!.firmId!,
              _case: tempCase,
            });
            setCacheNewCase(newCase);
          }
          // Create a new external case
          if (tempCase.integration?.identifier) {
            if (!cacheNewCase) {
              await caseApi.caseExternalIntegration({
                firmId: user!.firmId!,
                id: newCase?.id!,
                externalIntegration: tempCase.integration,
              });
            }
            newCase!.integration = tempCase.integration;
            // Get external clients
            try {
              await fetchExternalClients();
            } catch (error) {
              setFormError(ERROR_MSG.EXTERNAL_PARTNER.GENERAL);
              rg4js('send', {
                error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CLIENT_FETCH),
              });
              setSaving(false);
              return;
            }
          }

          tempCase = newCase!;
          document.clientId = null;
        } catch (error) {
          setFormError(ERROR_MSG.GENERAL);
          rg4js('send', {
            error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_CREATION + `Data: {${error}}`),
          });
          setSaving(false);
          return;
        }
      } else {
        // If the current case already exists
        if (_case.integration?.identifier) {
          try {
            if (_case.jurisdiction === blankId || _case.venue === blankId) {
              tempCase.jurisdiction = document.info?.jurisdiction ?? CaliforniaId;
              tempCase.venue = venue;
              try {
                await caseApi.casePut({ firmId: user!.firmId!, id: tempCase.id!, _case: tempCase })
              } catch (error) {
                setFormError(ERROR_MSG.GENERAL);
                rg4js('send', {
                  error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_JURISDICTION_VENUE_SET + ` Data: {${error}}`),
                });
                setSaving(false);
              }
            }
            const res = await externalRequestWrapper(clientApi.clientGetExternal({ firmId: user?.firmId!, externalCaseId: tempCase.integration?.identifier! }), 3);
            handleExternalReqResCodes(res, exClientFetchSuccess, exClientFetchError, initiateAuth);
          } catch (error) {
            setFormError(ERROR_MSG.GENERAL);
            rg4js('send', {
              error: new Error(ERROR_MSG.EXTERNAL_PARTNER.FAILED_CASE_CLIENT_FETCH + ` Data: {${error}}`),
            });
            setSaving(false);
            return;
          }
        }

        if ((!_case.caseNumber && caseNumber) || (!_case.judge && judge)) {
          tempCase.caseNumber ??= caseNumber;
          tempCase.judge ??= judge;
          try {
            await caseApi.casePut({
              firmId: user!.firmId!,
              id: tempCase.id!,
              _case: tempCase,
            });
          } catch (error) {
            setFormError(ERROR_MSG.GENERAL);
            setSaving(false);
            return;
          }
        }
        document.clientId = tempCase.clientId;
      }

      if (!client?.id) {
        // Create a client ID if none exists
        if (client && client.aliases) {
          client.aliases = pleadClientPos?.name;
        }

        try {
          let setDefendantName = (clientIsDefendant && respondingParty === PropoundingParty.Defendant) || (!clientIsDefendant && respondingParty === PropoundingParty.Plaintiff) ? propoundingPartyName : respondingPartyName;
          let setPlaintiffName = !((clientIsDefendant && respondingParty === PropoundingParty.Defendant) || (!clientIsDefendant && respondingParty === PropoundingParty.Plaintiff)) ? propoundingPartyName : respondingPartyName;
          let clientName = clientIsDefendant ? setDefendantName : setPlaintiffName;

          const newClient = await clientApi.clientPost({
            firmId: user!.firmId!,
            client: { name: clientName },
          });
          tempClient = newClient;
        } catch (error) {
          setFormError(ERROR_MSG.GENERAL);
          setSaving(false);
          return;
        }
      }

      if (!document.clientId) {
        document.clientId = tempClient.id;
        tempCase.clientId = tempClient.id;
      }

      // Adds client to case
      try {
        await caseApi.caseAddClient({
          firmId: user!.firmId!,
          id: tempCase.id!,
          body: tempClient.id!,
        });
      } catch (error) {
        setFormError(ERROR_MSG.GENERAL);
        setSaving(false);
        return;
      }

      if ((!tempCase!.clientPosition || tempCase!.clientPosition === PartyPosition.Unknown) && pleadClientPos?.clientPos) {
        tempCase!.clientPosition = GetEnumValues(pleadClientPos.clientPos).join(", ") as PartyPosition;
        try {
          await caseApi.casePut({
            firmId: user!.firmId!,
            id: tempCase!.id!,
            _case: tempCase,
          });
        } catch (error) {
          setFormError(ERROR_MSG.GENERAL);
          setSaving(false);
          return;
        }
      }

      if (!tempCase?.otherParties?.length) {
        try {
          let setDefendantName = (clientIsDefendant && respondingParty === PropoundingParty.Defendant) || (!clientIsDefendant && respondingParty === PropoundingParty.Plaintiff) ? propoundingPartyName : respondingPartyName;
          let setPlaintiffName = !((clientIsDefendant && respondingParty === PropoundingParty.Defendant) || (!clientIsDefendant && respondingParty === PropoundingParty.Plaintiff)) ? propoundingPartyName : respondingPartyName;
          let otherName = !clientIsDefendant ? setDefendantName : setPlaintiffName;

          await caseApi.caseCreateParty({
            firmId: user!.firmId!,
            id: tempCase!.id!,
            party: {
              name: otherName,
              position: clientIsDefendant ? PartyPosition.Plaintiff : PartyPosition.Defendant
            },
          });
        } catch (error) {
          setFormError(ERROR_MSG.GENERAL);
          setSaving(false);
          return;
        }
      }

      try {
        await caseApi.casePut({
          firmId: user!.firmId!,
          id: tempCase!.id!,
          _case: tempCase,
        });
      } catch (error) {
        setFormError(ERROR_MSG.GENERAL);
        setSaving(false);
        return;
      }

      document.caseId = tempCase.id;
      document.info!.venue = venue;
      if (await handleUpdateDocument()) {
        setCase(tempCase);
        setSection("page2Pd");
        setConfirmedSections(["page1Pd"]);
      }
    } else if (section === 'page2Pd') {
      if (!caseType) {
        setFormError("Please select a Case Type.");
        setSaving(false);
        return;
      }
      let tempCase = _case!;

      tempCase.damages = damages;
      tempCase.incidentTime = incidentTime;
      tempCase.incidentDate = incidentDate;
      tempCase.incidentLocation = incidentLocation;
      tempCase.incidentDescription = incidentDescription;
      tempCase.timeline = timelineDetails;
      tempCase.caseType = caseType
      tempCase.causesOfAction = causesOfAction;
      tempCase.agreements = agreements;
      tempCase.injuries = injuries;
      tempCase.genericInfo = genericInfo;

      try {
        await caseApi.casePut({
          firmId: user!.firmId!,
          id: tempCase!.id!,
          _case: tempCase,
        });
      } catch (error) {
        setFormError(ERROR_MSG.GENERAL);
        setSaving(false);
        return;
      }

      if (await handleUpdateDocument()) {
        setSection("page3Pd");
        setConfirmedSections(["page1Pd", "page2Pd"]);
      }

    } else {
      let tempCase = _case!;
      tempCase.allegations = allegations;

      // NOTE TO SELF: Refactor this once flows are seperated.
      const resId = _case && _case.otherParties?.[0].id;

      if (document.info && document.info.caseMetadata) {
        document.info.caseMetadata.allegations = allegations;
      }

      try {
        await caseApi.casePut({
          firmId: user!.firmId!,
          id: tempCase!.id!,
          _case: tempCase,
        });
      } catch (error) {
        setFormError(ERROR_MSG.GENERAL);
        setSaving(false);
        return;
      }

      const createDiscoveryRequest: CreateDiscoveryRequest = {
        caseId: _case?.id,
        clientId: client?.id,
        setNumber: setNumber,
        startingNumber: startingNumber,
        respondingId: resId,
        questions: null,
        parentDocumentId: document.id
      };

      if (selectedRequestTypes.length > 0) {
        for (let i = 0; i < selectedRequestTypes.length; i++) {
          const t = selectedRequestTypes[i];

          createDiscoveryRequest.documentSubType = t;
          try {
            await documentApi.documentStartGenerateRequests({ createDiscoveryRequest });
          } catch (error) {
            setFormError(ERROR_MSG.GENERAL);
            setSaving(false);
            return;
          }
        }

        setGeneratingRequests(true);

      }

      document.status = DocumentStatus.InfoConfirmed;
      if (await handleUpdateDocument()) {
        setCase(tempCase);
        if (selectedRequestTypes.length === 0) window.location.href = ('/');
      }
      setSection("page3Pd");
      setConfirmedSections(["page1Pd", "page2Pd", "page3Pd"]);
    }
    setSaving(false);
    return;

  }

  async function onSubmit() {
    setSaving(true);
    // Take to pleadings SDR flow if its a pleading/complain, else, go to regular sdr flow
    if (documentType === DocumentType.Pleading) {
      sdrPleadingUiFlow();
    } else {
      sdrUiFlow();
    }

  }

  return (
    <Container className="page-container">
      <header className="mb-3">
        <h1>Source Document Review</h1>
        <p>Let’s quickly confirm key pieces of data before we get started.</p>
      </header>
      {formError && <Alert variant="danger">{formError}</Alert>}
      <Row>
        <Col xs="6">
          {
            /* <DocumentOutline
            activeSection={section}
            confirmedSections={confirmedSections}
            setActiveRef={setActiveRef}
            setActiveLineRef={setActiveLineRef}
          /> */
          }
          <div className="document-preview-container">
            <PdfViewer documentId={document.id} height={830} width={740} />
          </div>
        </Col>
        <Col xs="5">
          <div className="confirm-information-container"// ref={(ref) => (ref ? setInformationRef(ref) : null)}
          >
            {document.info?.documentType === DocumentType.Pleading && !useComplaints ?
              <><Alert variant="warning">
                <p className="mb-0"><strong>Please note</strong> - Currently, processing Pleadings is a work in progress and will be available at a later date.</p>
              </Alert>
                <Button
                  className="return-button"
                  onClick={() => history.push("/documents")}>
                  Return to Dashboard
                </Button>
              </> :
              <>
                <SourceDocumentReviewSteps
                  numSteps={sections.length}
                  activeIndex={confirmedSections.length}
                  completedStepIndexes={completedSteps}
                  onStepClick={jumpTo}
                />
                {(!saving && generatingRequests) ?
                  <>
                    {/*Add Animated Gif Here once its finished*/}
                    <div className={styles.pleadingSuccess}>
                      <svg style={{ marginTop: "-2px" }} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_15_3207)">
                          <path d="M0.208008 6.18145C0.208008 6.18145 2.23034 6.06688 3.56788 4.43251C4.90543 2.79814 5.36195 0.0146484 5.36195 0.0146484C5.36195 0.0146484 5.8385 2.78803 7.05991 4.40555C8.28132 6.02307 10.3918 6.18482 10.3918 6.18482C10.3918 6.18482 8.39746 6.33984 7.12399 7.8967C5.85052 9.45357 5.37797 11.9473 5.37797 11.9473C5.37797 11.9473 4.89742 9.58499 3.50782 7.85964C2.2784 6.32636 0.208008 6.18145 0.208008 6.18145Z" fill="currentColor" >
                            <animate attributeName="opacity"
                              values=".2;1;.2" dur="2.5s" begin='-2s'
                              repeatCount="indefinite" />
                          </path>
                          <path d="M7.09619 11.8428C7.09619 11.8428 8.55788 11.7619 9.527 10.5791C10.4961 9.39629 10.8245 7.38449 10.8245 7.38449C10.8245 7.38449 11.1689 9.38955 12.0539 10.5589C12.9389 11.7282 14.4647 11.8462 14.4647 11.8462C14.4647 11.8462 13.023 11.9574 12.102 13.0829C11.1809 14.2084 10.8405 16.0113 10.8405 16.0113C10.8405 16.0113 10.4921 14.3028 9.48695 13.0559C8.59392 11.9473 7.1002 11.8428 7.1002 11.8428H7.09619Z" fill="currentColor" >
                            <animate attributeName="opacity"
                              repeatCount="indefinite" />
                          </path>
                          <path d="M9.4669 3.73833C9.4669 3.73833 10.5802 3.6743 11.317 2.77792C12.0539 1.88154 12.3022 0.348267 12.3022 0.348267C12.3022 0.348267 12.5625 1.8748 13.2352 2.76107C13.908 3.64734 15.0654 3.7417 15.0654 3.7417C15.0654 3.7417 13.9681 3.82594 13.2673 4.68188C12.5665 5.53782 12.3062 6.90935 12.3062 6.90935C12.3062 6.90935 12.0419 5.60859 11.277 4.66166C10.6002 3.8192 9.46289 3.73833 9.46289 3.73833H9.4669Z" fill="currentColor" >
                            <animate attributeName="opacity"
                              values=".2;1;.2" dur="2.5s"
                              repeatCount="indefinite" />
                          </path>
                        </g>
                        <defs>
                          <clipPath id="clip0_15_3207">
                            <rect width="14.8571" height="16" fill="white" transform="translate(0.208008 0.0146484)" />
                          </clipPath>
                        </defs>
                      </svg>
                      <p>Creating Tailored Discovery... </p>
                      <p>This may take a few moments. If you navigate away from this page, you will be able to access your discovery from the <Link to={`/?highlight=${document.id}`}>Document List</Link>.</p>

                    </div>
                  </> : <>
                    <FormPreventDefault onSubmit={onSubmit}>
                      <SectionComponent
                        jurisdiction={jurisdictions.find((j) =>
                          j.id === document.info?.jurisdiction ?? CaliforniaId
                        )}
                        jurisdictions={jurisdictions}
                        venue={venue}
                        setVenue={setVenue}
                        caseNumber={caseNumber}
                        setCaseNumber={setCaseNumber}
                        caseMetaData={caseMetaData}
                        setCaseMetaData={setCaseMetaData}
                        documentType={documentType}
                        // setDocumentType={setDocumentType}
                        documentSubType={documentSubType}
                        setDocumentSubType={setDocumentSubType}
                        propoundingParty={propoundingParty}
                        setPropoundingParty={setPropoundingParty}
                        respondingParty={respondingParty}
                        setRespondingParty={setRespondingParty}
                        docPropoundingName={document.info?.propoundingPartyName}
                        propoundingPartyName={propoundingPartyName}
                        setPropoundingPartyName={setPropoundingPartyName}
                        respondingPartyName={respondingPartyName}
                        setRespondingPartyName={setRespondingPartyName}
                        damages={damages}
                        firm={firm}
                        caseType={caseType}
                        setCaseType={setCaseType}
                        pleadClientPos={pleadClientPos}
                        setPleadClientPos={setPleadClientPos}
                        setDamages={setDamages}
                        setClientIsDefendant={setClientIsDefendant}
                        clientIsDefendant={clientIsDefendant}
                        incidentDate={incidentDate}
                        setIncidentDate={setIncidentDate}
                        incidentDescription={incidentDescription}
                        setIncidentDescription={setIncidentDescription}
                        incidentLocation={incidentLocation}
                        setIncidentLocation={setIncidentLocation}
                        incidentTime={incidentTime}
                        setIncidentTime={setIncidentTime}
                        isCrossComplaint={isCrossComplaint}
                        setIsCrossComplaint={setIsCrossComplaint}
                        causesOfAction={causesOfAction}
                        setCausesOfAction={setCausesOfAction}
                        selectedRequestTypes={selectedRequestTypes}
                        setSelectedRequestTypes={setSelectedRequestTypes}
                        // complaintDate={complaintDate} 
                        // setComplaintDate={setComplaintDate}
                        genericInfo={genericInfo}
                        setGenericInfo={setGenericInfo}
                        timelineDetails={timelineDetails}
                        setTimelineDetails={setTimelineDetails}
                        allegations={allegations}
                        setAllegations={setAllegations}
                        injuries={injuries}
                        setInjuries={setInjuries}
                        agreements={agreements}
                        setAgreements={setAgreements}
                        judge={judge}
                        setJudge={setJudge}
                        caseId={document.caseId || undefined}
                        showEdit={showEdit}
                        setShowEdit={setShowEdit}
                        _case={_case || undefined}
                        setCase={setCase}
                        client={client || undefined}
                        setClient={setClient}
                        cases={cases}
                        externalCases={externalCases}
                        filterExternalCases={handleFilterChange}
                        clients={clients}
                        externalClients={externalClients}
                        dateOfService={dateOfService}
                        setDateOfService={setDateOfService}
                        methodOfService={methodOfService}
                        setMethodOfService={setMethodOfService}
                        dueDate={dueDate}
                        setDueDate={setDueDate}
                        respondent={respondent}
                        setRespondent={updateRespondent}
                        respondentCustom={respondentCustom}
                        setRespondentCustom={setRespondentCustom}
                        setNumber={setNumber}
                        setSetNumber={setSetNumber}
                        startingNumber={startingNumber}
                        setStartingNumber={setStartingNumber}
                        isExternalCasesLoading={isExternalCasesLoading}
                      />
                      <div className="text-end mt-3">
                        {section === "page1" && document.caseId && (showEdit
                          ? (
                            <Button variant="link" onClick={cancelShowEdit}>
                              Cancel
                            </Button>
                          )
                          : null)}
                        <Button
                          style={{ marginLeft: "8px" }}
                          loading={saving}
                          disabled={!_case}
                          text="Saving..."
                          type="submit"
                        >
                          Confirm
                        </Button>
                      </div>
                    </FormPreventDefault>
                  </>
                }
              </>
            }
          </div>
        </Col>
      </Row>
    </Container>
  );
}
