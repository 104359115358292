import { AlertType, DocumentStatus, DocumentType } from 'briefpoint-client';
import Help from 'components/Help';
import { Spinner } from 'react-bootstrap';
import { Check, Exclamation, ExclamationTriangleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { DocumentClient } from 'services/DocumentService';
import { formatLongDate } from 'utils/dateUtils';
import ArchiveIcon from './ArchiveIcon';
import { useInterval } from 'usehooks-ts';

interface LoadingIconProps {
  complete: boolean;
  error: boolean;
  fixInProgress: boolean;
}

function StatusIcon({ complete, fixInProgress, error }: LoadingIconProps) {
  return fixInProgress ? (
    <div className="fixInProgress">
      <ExclamationTriangleFill />
    </div>
  ) : error ? (
    <div className="error-icon">
      <Exclamation />
    </div>
  ) : complete ? (
    <div className="complete-icon">
      <Check />
    </div>
  ) : (
    <Spinner style={{ width: "1.3rem", height: "1.3rem", marginTop: '12px' }} animation="border" variant="success">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

interface Props {
  document: DocumentClient;
  archiveDocument: (document: DocumentClient) => Promise<void>;
  processingType?: string;
  estimatedProgress: number;
  setEstimatedProgress: (docId: string, progress: number) => void;
}

export default function ProcessingBox({ document, archiveDocument, processingType = 'Processing', estimatedProgress, setEstimatedProgress }: Props) {
  const uploadedDateTime = formatLongDate(document.uploadDate);
  const filename = document.name;
  const error = document.status === DocumentStatus.Error;
  const fixInProgress = document.alert === AlertType.FixInProgress
  const progress = (document.info?.parsingProgress ?? 0) * 100;

  // This interval estimates the current load percentage
  const tickRt = 500;
  const identifyPercentage = 40;
  const averageTimeToIdentifyFileType = 20000; // 20 seconds in ms

  useInterval(() => {
    if (progress !== 100) {
      // If the progress is above the estimated progress, update it
      if (progress > estimatedProgress) {
        // Set the estimated progress to a percentage of the difference to smooth it out
        const p = (progress - estimatedProgress) * 0.1;
        setEstimatedProgress(document.id, estimatedProgress + p);
      }
      // Else if we have not identified the file, tick up 
      else if (estimatedProgress <= identifyPercentage) {
        const amtToAdd = (averageTimeToIdentifyFileType / tickRt) / identifyPercentage;
        setEstimatedProgress(document.id, estimatedProgress + amtToAdd);
      }
    } else {
      // We have finished; quickly fill the bar
      if ((progress - estimatedProgress) > 5) {
        setEstimatedProgress(document.id, (estimatedProgress + progress) / 2);
      } else {
        setEstimatedProgress(document.id, 100);
      }
    }
  }, tickRt);

  const showComplete = estimatedProgress >= 100;

  const containerClassName = fixInProgress ? fixInProgress : error ? 'error' : showComplete ? 'complete' : processingType;
  let processingText = "";
  if (!error && estimatedProgress > 0 && estimatedProgress <= 39) {
    processingText = "Identifying document...";
  }
  else if (!error && estimatedProgress > 39 && estimatedProgress <= 99) {
    processingText = `Extracting ${document.info?.documentType === DocumentType.Pleading ? 'case data' : 'requests'}...`;
  }

  const heading = fixInProgress ? 'Fix in progress for' : error
    ? `Error ${processingType.toLowerCase()}`
    : showComplete
      ? `${processingType} complete:`
      : uploadedDateTime
        ? `Currently ${processingType.toLowerCase()}`
        : 'Uploading';

  return (

    <div id={`processing-box-${document.id}`} className={`processing-box ${containerClassName}`}>
      <div className='details'>
        <StatusIcon complete={showComplete} fixInProgress={fixInProgress} error={error} />
        <div className="details">
          <div className="details-inner">
            <small className="details-text">{heading} <b>{filename}.</b></small>
            <small className="details-text">{processingText}</small>
            {fixInProgress ?
              <small className="header-two-wrap">
                Please check back later.
              </small>
              :
              <></>}
            {error && (
              <Help
                id={`${document.id}-error`}
                text="Briefpoint encountered an issue processing your document and will investigate further, you can also let us know by using the Feedback button on the right."
              />
            )}
            {/* This text should be smaller than small  */}
            {uploadedDateTime && showComplete && <small className="details-text">Uploaded {uploadedDateTime}.</small>}
          </div>

        </div>
        {
          showComplete && !error && (
            <div className="startedLink-wrapper">
              <Link
                className="btn btn-outline-secondary btn-sm wide"
                to={`/wizard/${document.id}/source-document-review?document_type=false`}
              >
                Get Started
              </Link>
            </div>
          )
        }
        <div className='progress-pd'><ArchiveIcon document={document} archiveDocument={archiveDocument} /></div>
      </div>
      {!(estimatedProgress >= 100) && !error && (<div className='loading-bar' style={{ width: `${estimatedProgress}%` }}></div>)}
    </div >
  );
}