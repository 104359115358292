import { Modal } from "components/Modals";
import { Alert, Form } from "react-bootstrap";
import { Case, Client, DocumentSubType, Jurisdiction, ExternalClient, ExternalCase, ExternalCaseIEnumerableExternalPartnerResponse } from "briefpoint-client";
import { CombinedCaseSearch, mergeCases } from "components/ConfirmInfo/ReviewPageOne";
import { RenderMenuProps } from "react-bootstrap-typeahead/types/components/Typeahead";
import { Typeahead } from "react-bootstrap-typeahead";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Option } from "react-bootstrap-typeahead/types/types";
import styles from './QuickActionModal.module.scss';
import { CaliforniaId } from "components/CaseManagement/AddEditCaseModal";
import { CustomMenu, NoSuggestCustomMenuClient } from "components/ConfirmInfo/utils";
import { debounce } from "lodash";
import { useAuth } from "hooks/useAuth";
import { useCaseApi } from "hooks/useApi";
import { initiateAuth } from "utils/ExternalPartner/utils";
import rg4js from "raygun4js";

export default function QuickActionModal(
  {
    isShow,
    uploadOnly,
    quickActionIsSaving,
    formError,
    getFirmJx,
    isExternalConnectionActive,
    cases,
    quickActionCase,
    clients,
    quickActionClient,
    quickActionExternalClients,
    quickActionType,
    setFormError,
    setQuickActionCase,
    setQuickActionClient,
    setQuickActionType,
    renderExternalPartnerActions,
    handleQuickActionExternalUpload,
    fetchExternalClients,
    onClose,
    onConfirm,
  }: {
    isShow: boolean;
    uploadOnly?: boolean;
    quickActionIsSaving: boolean;
    formError: string | undefined;
    getFirmJx: Jurisdiction | undefined;
    isExternalConnectionActive?: boolean;
    cases: Case[] | undefined;
    quickActionCase: Case | undefined;
    clients: Client[] | undefined;
    quickActionClient: Client | undefined;
    quickActionExternalClients: ExternalClient[] | undefined;
    quickActionType: DocumentSubType | undefined;
    setFormError: React.Dispatch<React.SetStateAction<string | undefined>>;
    setQuickActionCase: React.Dispatch<React.SetStateAction<Case | undefined>>;
    setQuickActionClient: React.Dispatch<React.SetStateAction<Client | undefined>>;
    setQuickActionType: React.Dispatch<React.SetStateAction<DocumentSubType | undefined>>;
    renderExternalPartnerActions: (toggleOnly?: boolean) => JSX.Element;
    handleChooseFile: (e: React.MouseEvent) => void;
    handleQuickActionExternalUpload?: () => void;
    fetchExternalClients: (externalCase: Case) => Promise<void>
    onClose: () => void;
    onConfirm: (value: DocumentSubType) => void;
  },
) {

  const { user } = useAuth()!;
  const [isLoadingExternal, setIsLoadingExternal] = useState<boolean>(false);
  const [isLoadingCasesExternal, setIsLoadingCasesExternal] = useState<boolean>(false);
  const caseApi = useCaseApi();
  const [allExternalCases, setAllExternalCases] = useState<ExternalCase[] | undefined>();

  const errCheck = !quickActionCase || !quickActionClient || !quickActionType;
  let isCurClientMatch: boolean = (quickActionCase?.clientId !== undefined) && (quickActionClient?.id !== undefined) && (quickActionCase?.clientId === quickActionClient?.id);

  function onSubmit() {
    onConfirm(quickActionType!);
  }

  function handleClientChange(e: Option[]): void {
    const val = e[0];
    if (val) {
      if ((val as any).customOption) {
        setQuickActionClient({ name: (val as any).name });
      } else {
        const c = val as Client;
        setQuickActionClient(c);
      }
    } else {
      setQuickActionClient(undefined);
    }
  }

  async function handleCaseChange(e: Option[]): Promise<void> {
    const val = e[0];

    if (val) {
      if ((val as any).customOption) {
        setQuickActionCase({ shortTitle: (val as any).label, title: (val as any).value });
        setQuickActionClient(undefined);
      } else {
        const c = val as CombinedCaseSearch;
        let _case = cases?.find(x => x.id === c.internalId && x.integration?.identifier === c.externalId);

        if (!_case) {
          const integration = c.externalId ? { partner: c.partner!, identifier: c.externalId! } : undefined;
          _case = { shortTitle: c.title, integration };
        }

        if (_case.integration?.partner && !_case.clientId && isExternalConnectionActive) {
          setIsLoadingExternal(true);
          try {
            await fetchExternalClients(_case);
            if (formError) {
              setQuickActionCase(undefined);
              setQuickActionClient(undefined);
            } else {
              setQuickActionCase(_case);
            }
          } catch (error) {
            setQuickActionCase(undefined);
            setQuickActionClient(undefined);
            setFormError("There was an error retrieving cases, please try again later.");
            throw new Error("There was an error fetching external clients for this case.");
          }
          setIsLoadingExternal(false);
        } else {
          setQuickActionCase(_case);
          setQuickActionClient(clients?.find((x) => x.id === _case?.clientId));
          setFormError(undefined);
        }
      }
    } else {
      setQuickActionCase(undefined);
      setFormError(undefined);
    }
  }

  const handleExternalCaseSet = (fetchedExternalCases: void | ExternalCaseIEnumerableExternalPartnerResponse) => {
    if (fetchedExternalCases) {
      if (fetchedExternalCases.responseCode === 200) {
        setAllExternalCases(fetchedExternalCases.data ?? []);
      } else {
        if (fetchedExternalCases.responseCode === 401) {
          // initiate re-auth
          initiateAuth();
        } else {
          rg4js('send', {
            error: new Error("There was an error retrieving external client data."),
          });
        }
      }
    }
  };

  const loadExternalCases = useCallback(async (filter?: string) => {
    if (user?.firmId && user?.externalConnection?.isActive) {
      setIsLoadingCasesExternal(true);

      const external = await caseApi.caseGetExternal({ firmId: user.firmId!, filter });
      handleExternalCaseSet(external);
      setIsLoadingCasesExternal(false);

    }
  }, [caseApi, user?.externalConnection?.isActive, user?.firmId]);

  const handleFilterChange = useCallback(async (filter?: string) => {
    await loadExternalCases(filter);
  }, [loadExternalCases]);

  const debouncedFilter = useMemo(() => debounce(async (filter?: string) => {
    await handleFilterChange(filter);
  }, 400), [handleFilterChange]);

  useEffect(() => {
    async function loadExternal() {
      await loadExternalCases();
    };
    loadExternal();
  }, [loadExternalCases, user]);

  const supportsFrogs = getFirmJx?.id === CaliforniaId;

  const sortedCases: CombinedCaseSearch[] = mergeCases(cases, allExternalCases).sort((a, b) => {
    // Continue with the regular alphabetical sort by title
    const shortTitleA = a.title || "";
    const shortTitleB = b.title || "";
    return shortTitleA.localeCompare(shortTitleB);
  });

  function filterCallback(option: any, props: { text: string }) {
    return option.title.toLowerCase().includes(props.text.toLowerCase());
  }

  function getLabelKey(option: any) {
    return option.shortTitle || option.title;
  }

  return (
    <Modal
      show={isShow}
      onClose={onClose}
      onSecondaryAction={handleQuickActionExternalUpload}
      onConfirm={onSubmit}
      showConfirmButton={!uploadOnly}
      disableConfirmButton={errCheck || quickActionIsSaving}
      showCancelButton={false}
      cancelText="Cancel"
      confirmText={uploadOnly ? "Upload Document" : "Next"}
      size="lg"
      title={uploadOnly ? "Response to Discovery" : "Propound Discovery"}
    >
      {uploadOnly
        ? (
          <>
            <p>To draft a response to a discovery request, please upload a .pdf of one of the following:</p>
            <ul>
              <li>Requests for Admission </li>
              <li>Requests for Production</li>
              <li>{supportsFrogs && 'Special '}Interrogatories</li>
              {supportsFrogs && <li>Form Interrogatories</li>}
            </ul>
            <div className={styles.quickAccBtnContainer}>
              {renderExternalPartnerActions()}
            </div>
          </>
        )
        : (
          <>
            <div className="mb-3">
              {formError && <Alert variant="danger">{formError}</Alert>}
              {renderExternalPartnerActions(true)}
              <label className="mt-1" htmlFor="case-select">Case</label>
              <Typeahead
                allowNew
                clearButton
                className={
                  quickActionCase && isExternalConnectionActive
                    ? quickActionCase.integration?.partner
                      ? styles[`external-partner-${quickActionCase.integration.partner}`]
                      : styles[`external-partner-none`]
                    : ''
                }
                filterBy={filterCallback}
                onChange={(e) => handleCaseChange(e)}
                selected={(quickActionCase && [quickActionCase]) || []}
                id="case"
                newSelectionPrefix="Create a new Case: "
                emptyLabel={!quickActionCase?.id ? "No matches found, Type to add a new case." : "No matches found."}
                options={sortedCases || []}
                renderMenu={(results: any, menuProps: RenderMenuProps, state: any) => (
                  <CustomMenu results={results} menuProps={menuProps} state={state} showSourceLogos={!!user?.externalConnection?.isActive} />
                )}
                placeholder="Find or create a Case..."
                labelKey={getLabelKey}
                isLoading={isLoadingCasesExternal}
                onInputChange={(f) => debouncedFilter(f)}
              />
              <label htmlFor="client-select">Client</label>
              {!isCurClientMatch ? <Typeahead
                clearButton
                className={
                  quickActionClient && isExternalConnectionActive
                    ? quickActionClient?.integration?.partner
                      ? styles[`external-partner-${quickActionClient.integration.partner}`]
                      : styles[`external-partner-none`]
                    : ''
                }
                onChange={(e) => handleClientChange(e)}
                selected={(quickActionClient && [quickActionClient]) || []}
                allowNew={!quickActionCase?.clientId}
                id="client"
                newSelectionPrefix="Create a new Client: "
                emptyLabel={!quickActionCase?.clientId ? "No matches found, Type to add a new client." : "No matches found."}
                options={quickActionExternalClients || clients || []}
                renderMenu={(results: any, menuProps: RenderMenuProps, state: any) => (
                  <NoSuggestCustomMenuClient results={results} menuProps={menuProps} state={state} showSourceLogos={!!isExternalConnectionActive} />
                )}
                placeholder={!quickActionCase?.id ? "Find or create a Client..." : "Find a client..."}
                disabled={isLoadingExternal}
                isLoading={isLoadingExternal}
                labelKey="name"
              /> : <p>{quickActionClient?.name}</p>}
            </div>
            <label htmlFor="client">Request Type</label>
            <div style={{ minHeight: "40px", marginBottom: "15px" }}>
              <Form.Check
                type="radio"
                name="requestType"
                label={`Request for Admission`}
                id={`request-for-admission`}
                value={DocumentSubType.RequestsForAdmission}
                onChange={() => setQuickActionType(DocumentSubType.RequestsForAdmission)}
              />
              <Form.Check
                type="radio"
                name="requestType"
                label={`Request for Production`}
                id={`request-for-production`}
                value={DocumentSubType.RequestsForProduction}
                onChange={() => setQuickActionType(DocumentSubType.RequestsForProduction)}
              />
              <Form.Check
                type="radio"
                name="requestType"
                label={getFirmJx?.id === CaliforniaId ? `Special Interrogatories` : 'Interrogatories'}
                id={`interrogatories`}
                value={DocumentSubType.SpecialInterrogatorries}
                onChange={() => setQuickActionType(DocumentSubType.SpecialInterrogatorries)}
              />
            </div>
          </>
        )}
    </Modal>
  );
}
