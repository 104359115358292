import React, { useCallback, useMemo, useRef, useState } from "react";
import { ButtonGroup, Col, Form, Row, ToggleButton } from "react-bootstrap";
import Button from "components/Button";
import { ExternalCase, type Case, type Client, type Jurisdiction, ExternalPartners, DocumentType, CaseMetadata, CollectionItem, PropoundingParty } from "briefpoint-client";
import { type Option } from "react-bootstrap-typeahead/types/types";
import { Typeahead } from "react-bootstrap-typeahead";
import { CustomMenu, NoSuggestCustomMenuClient } from "./utils";
import styles from "./ReviewPage.module.scss";
import { RenderMenuProps } from "react-bootstrap-typeahead/types/components/Typeahead";
import ExternalPartnerConnection from "components/ExternalPartner/ExternalPartnerConnection";
import { useAuth } from "hooks/useAuth";
import Loading from "components/Loading";
import { ReactComponent as SwapIcon } from "../../images/swap_vert.svg";
import { debounce } from "lodash";

type GenericArrayProps = Array<CollectionItem> | null;

export class CombinedCaseSearch {
  constructor(public title: string, public search: string, public partner?: ExternalPartners, public externalId?: string, public internalId?: string) { }
}

const buildSearchString = (_case: Case) => {
  return `${_case.shortTitle} ${_case.title}`;
}

const buildSearchStringExternal = (_case: ExternalCase) => {
  return `${_case.title}`;
}

export const mergeCases = (cases?: Case[], externalCases?: ExternalCase[]) => {
  let mappedCases = cases?.map(c => new CombinedCaseSearch(c.shortTitle ?? c.title ?? '', buildSearchString(c), c.integration?.partner, c.integration?.identifier ?? undefined, c.id)) ?? [];
  if (!externalCases?.length) {
    return mappedCases;
  }

  let mappedExternal = externalCases.filter(x => x.title).map(c => new CombinedCaseSearch(c.title!, buildSearchStringExternal(c), c.partner!, c.id!, undefined));

  for (let i = 0; i < mappedExternal.length; i++) {
    const element = mappedExternal[i];

    const internal = mappedCases.find(x => x.partner === element.partner && x.externalId === element.externalId);
    if (internal) {
      element.internalId = internal.internalId;
      mappedCases = mappedCases.filter(c => c.internalId !== internal.internalId);
    }
  }

  return mappedCases.concat(mappedExternal);
}

// Venue, Case#, Judge
function ReviewPageOnePd({
  documentType,
  jurisdiction,
  venue,
  setVenue,
  caseNumber,
  setCaseNumber,
  judge,
  setJudge,
  showEdit,
  setShowEdit,
  _case,
  setCase,
  client,
  clients,
  propoundingParty,
  docPropoundingName,
  propoundingPartyName,
  respondingParty,
  respondingPartyName,
  pleadClientPos,
  setPleadClientPos,
  setRespondingParty, 
  setPropoundingParty,  
  setRespondingPartyName,
  setPropoundingPartyName,
  setClient,
  cases,
  caseMetaData,
  externalCases,
  filterExternalCases,
  setClientIsDefendant,
  isExternalCasesLoading
}: {
  documentType: any;
  jurisdiction: Jurisdiction | undefined;
  venue: string;
  docPropoundingName: string | null | undefined;
  propoundingPartyName: string;
  respondingPartyName: string;
  caseNumber: string;
  judge: string;
  caseId?: string;
  showEdit: boolean;
  clientIsDefendant: boolean; 
  isExternalCasesLoading?: boolean;
  pleadClientPos: { name: string | undefined, clientPos: PropoundingParty | undefined } | undefined;
  _case?: Case;
  caseMetaData: CaseMetadata | undefined;
  client?: Client;
  clients?: Client[];
  cases?: Case[];
  externalCases?: ExternalCase[];
  propoundingParty: PropoundingParty | undefined;
  respondingParty: PropoundingParty | undefined;
  setCase: React.Dispatch<React.SetStateAction<Case | undefined>>;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setJudge: React.Dispatch<React.SetStateAction<string>>;
  setPleadClientPos: React.Dispatch<React.SetStateAction<{ name: string | undefined, clientPos: PropoundingParty | undefined } | undefined>>;
  setRespondingParty: React.Dispatch<React.SetStateAction<PropoundingParty | undefined>>;
  setPropoundingParty: React.Dispatch<React.SetStateAction<PropoundingParty | undefined>>;
  setRespondingPartyName: React.Dispatch<React.SetStateAction<string>>;
  setPropoundingPartyName: React.Dispatch<React.SetStateAction<string>>;
  setCaseNumber: React.Dispatch<React.SetStateAction<string>>;
  setVenue: React.Dispatch<React.SetStateAction<string>>;
  setClient: React.Dispatch<React.SetStateAction<Client | undefined>>;
  setClientIsDefendant: React.Dispatch<React.SetStateAction<boolean>>;
  filterExternalCases: (filter?: string) => Promise<void>;
}) {
  const { user } = useAuth()!;

  // *** RESPONDING/PROPOUNDING RELATED LOGIC *** //
  let isCurClientMatch: boolean = (_case?.clientId !== undefined) && (client?.id !== undefined) && (_case?.clientId === client?.id);
  let setClientCheck = propoundingParty ? propoundingParty === PropoundingParty.Defendant ? true : propoundingParty === PropoundingParty.Plaintiff ? false : true : true;

  const [isCaseDirty, setIsCaseDirty] = useState<boolean>(false);
  const [checkClientPosition, setClientPosition] = useState<boolean | null>(setClientCheck);
  const [defendantOptions] = useState<GenericArrayProps>(caseMetaData?.defendants ?? null);
  // const [plaintiffOptions] = useState<GenericArrayProps>(caseMetaData?.plaintiffs ?? null);
  const [isPartiesSwapped, setIsPartiesSwapped] = useState<boolean>(false);
  const [showClientMenu, setShowClientMenu] = useState<boolean>(false);
  const [showRespondingMenu, setShowRespondingMenu] = useState<boolean>(false);

  // Swaps the current client's party name
  const swapPartyNames = (name: string | undefined) => {
    if (name === respondingPartyName) {
      return propoundingPartyName ?? '';
    } else if (name === propoundingPartyName) {
      return respondingPartyName ?? '';
    } else {
      return name;
    }
  }

  // Update the client state info when we update the client position and ensure if selected client already exists or not
  const updateClientPos = (clientPosition: PropoundingParty) => {
    setPleadClientPos((prevState) => {
      const updatedName = swapPartyNames(prevState?.name);
      const newState = { name: updatedName, clientPos: clientPosition };

      // matchExistingClient(updatedName ?? '');

      return newState;
    });
  }

  const handleClientPositionCheck = (checked: boolean) => {

    if (checked) {
      setClientIsDefendant(true);
      updateClientPos(PropoundingParty.Defendant);
      setPropoundingParty(PropoundingParty.Defendant);
      setRespondingParty(PropoundingParty.Plaintiff);
      setClientPosition(checked);
    } else {
      setClientIsDefendant(false);
      updateClientPos(PropoundingParty.Plaintiff);
      setPropoundingParty(PropoundingParty.Plaintiff);
      setRespondingParty(PropoundingParty.Defendant);
      setClientPosition(checked);
    }

  };

  function swapParties() {
    // Update the party swapped boolean to update the UI fields
    setIsPartiesSwapped(prevState => !prevState);
    setRespondingPartyName(propoundingPartyName);
    setPropoundingPartyName(respondingPartyName);
    setPropoundingParty(respondingParty);
    setRespondingParty(propoundingParty);
    setClient((prevClientName) => ({ ...prevClientName, name: respondingPartyName }))
    updateClientPos(pleadClientPos?.clientPos === PropoundingParty.Defendant ? PropoundingParty.Plaintiff : PropoundingParty.Defendant);
  }

  function handleOnClientInputChange(text: string) {
    if (text) {
      setClient({ name: text });
      setPropoundingPartyName(text);
      setShowClientMenu(true);
    } else {
      setClient(undefined);
      setPropoundingPartyName('');
      setShowClientMenu(false);
    }
  }

  function handleOnRespondingInputChange(text: string) {
    if (text) {
      setRespondingPartyName(text);
      setShowRespondingMenu(true);
    } else {
      setRespondingPartyName('');
      setShowRespondingMenu(false);
    }
  }

  function handleClientChange(e: Option[]): void {
    const val = e[0];
    if (val) {
      if ((val as any).customOption) {
        setClient({ name: (val as any).name });
        setPropoundingPartyName((val as any).name);
        setPleadClientPos((prevState) => ({ clientPos: prevState?.clientPos, name: (val as any).name }));
        if (!isCaseDirty) {
          setCase({ shortTitle: isPartiesSwapped ? `${respondingPartyName} vs ${(val as any).name}` : `${(val as any).name} vs ${respondingPartyName ?? ''}` })
        }
        if (pleadClientPos?.clientPos === PropoundingParty.Plaintiff) {
          setPleadClientPos((prevState) => ({ clientPos: prevState?.clientPos, name: (val as any).name }));
        }
        setShowClientMenu(false);
      } else {
        const c = val as Client;
        setClient(c);
        setPropoundingPartyName((val as any).name);
        if (!isCaseDirty) {
          setCase({ shortTitle: isPartiesSwapped ? `${respondingPartyName} vs ${c.name}` : `${c.name} vs ${respondingPartyName ?? ''}` })
        }
        setPleadClientPos((prevState) => ({ clientPos: prevState?.clientPos, name: (c as any).name }));
        setShowClientMenu(false);
      }
    } else {
      setClient(undefined);
    }
  }

  function handleRespondingPartyChange(e: Option[]): void {
    const val = e[0];

    if (val) {
      const v = (val as any).text;
      setRespondingPartyName(v);
      if (!isCaseDirty) {
        setCase({ shortTitle: isPartiesSwapped ? `${v} vs ${propoundingPartyName ?? ''}` : `${propoundingPartyName ?? ''} vs ${v}`});
      }
      if (pleadClientPos?.clientPos === PropoundingParty.Defendant) {
        setPleadClientPos((prevState) => ({ clientPos: prevState?.clientPos, name: (val as any).text}));
      }
      setShowRespondingMenu(false);
      if (!!val) {
        setShowRespondingMenu(false);
      }
    }

  }

  // **** CASE/VENUE RELATED LOGIC **//
  // Checks if the current case is an existing case, if not, change toggle to 'New Case'
  let isExistingCase = !!_case && !!_case.id;

  // if the current case matches, default to the 'find case' field
  const [caseAction, setCaseAction] = useState<string>(isExistingCase || !showEdit ? "2" : "1");
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isExternalLoading, setIsLoadingExternal] = useState<boolean>(false);
  const caseTypeaheadRef = useRef<any>();
  const clientTypeaheadRef = useRef<any>();
  const respondTypeaheadRef = useRef<any>();
  const venues = jurisdiction?.venues;
  const caseActionTypes = [
    { name: "New Case", value: "1" }, { name: "Existing Case", value: "2" },
  ];

  function handleCaseChange(e: Option[]): void {
    const val = e[0];

    if (val) {
      const c = val as CombinedCaseSearch;
      let _case = cases?.find(x => x.id === c.internalId && x.integration?.identifier === c.externalId);

      if (!_case) {
        const integration = c.externalId ? { partner: c.partner!, identifier: c.externalId! } : undefined;
        _case = { shortTitle: c.title, integration };
      }

      setCase(_case);
      setIsCaseDirty(true);
      if (clients?.find((x) => x.id === _case?.clientId)) {
        setClient(clients?.find((x) => x.id === _case?.clientId));
      }
      setShowMenu(false);
      if (_case.venue) {
        setVenue(_case.venue);
      }

      if ((!!_case && !!_case.id) || _case.integration?.identifier) {
        setShowMenu(false);
        setCaseAction("2");
      } else {
        setCaseAction("1");
        setClient(undefined);
      }
    } 

  }

  function handleToggleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    setCaseAction(value);
    setCase(undefined);
    caseTypeaheadRef.current.focus();
    if (value === "1") {
      if (cases) {
        caseTypeaheadRef.current.toggleMenu();
      }
      setClient(undefined);
    }
  }

  function handleOnInputChange(text: string) {
    if (text) {
      const isMatching = sortedCases.some(option => option.title?.toLowerCase().includes(text.toLowerCase()));
      setCase({ shortTitle: text });
      setCaseNumber(caseNumber);
      setCaseAction("1");
      setShowMenu(isMatching);
      setIsCaseDirty(true);
    } else {
      setCase(undefined);
      setShowMenu(false);
      setIsCaseDirty(false);
    }
    debouncedFilter(text);
  }

  function filterCallback(option: any, props: { text: string }) {
    return option.title.toLowerCase().includes(props.text.toLowerCase());
  }

  function handleCaseChangeClick() {
    setCase(undefined)
    setClient(client && client.name === docPropoundingName ? client : { name: docPropoundingName ?? '' })
    setPropoundingPartyName(docPropoundingName ?? '');
    setShowEdit(true);
  }

  const handleFilterChange = useCallback(async (filter?: string) => {
    setIsLoadingExternal(true);
    await filterExternalCases(filter);
    setIsLoadingExternal(false);
  }, [filterExternalCases]);

  const debouncedFilter = useMemo(() => debounce(async (filter?: string) => {
    await handleFilterChange(filter);
  }, 400), [handleFilterChange]);

  function renderClientPosFields () {
    return (
      <Form.Group id="new-pleading-select" className="indent-radio">
        <label>What is your client's position?</label>
          <Row>
            <Col xs={12}>
              <Form.Check
              disabled
              id="pleadings-set-responding-plaintiff"
              type="radio"
              inline
              label="Plaintiff"
              checked={!checkClientPosition ?? undefined}
              onChange={(_) => handleClientPositionCheck(!_.target.checked)}
            />
              <Form.Check
              disabled
              id="pleadings-set-responding-defendant"
              type="radio"
              inline
              label="Defendant"
              checked={checkClientPosition ?? undefined}
              onChange={(_) => handleClientPositionCheck(_.target.checked)}
            />
            </Col>
          </Row>
        </Form.Group>
    )
  }

function renderPropoundFields() {
  return (
    <>
      {!isCurClientMatch ? (
        <Form.Group>
          <label>{isPartiesSwapped ? 'Defendant' : 'Plaintiff'}</label>
          <Typeahead
              allowNew={!_case?.clientId}
              clearButton
              id="field-propounding"
              labelKey="name"
              onChange={(e) => handleClientChange(e)}
              onInputChange={handleOnClientInputChange}
              selected={(client && [client]) || []}
              size="sm"
              isInvalid={!client || !propoundingPartyName}
              open={showClientMenu}
              newSelectionPrefix="Create a new Client: "
              emptyLabel={!_case?.clientId ? "No matches found, Type to add a new client." : "No matches found."}
              renderMenu={(results: any, menuProps: RenderMenuProps, state: any) => (
                <NoSuggestCustomMenuClient results={results} menuProps={menuProps} state={state} showSourceLogos={!!user?.externalConnection?.isActive} />
              )}
              options={clients || []}
              ref={clientTypeaheadRef}
          />
        </Form.Group>
      ) : (
        <p>{client?.name}</p>
      )}
    </>
  );
}

  function renderRespondingFields () {
    return (
      <div className="mb-3" style={{ position: 'relative' }}>
        <Form.Group>
        <label>{isPartiesSwapped ? 'Plaintiff' : 'Defendant'}</label>
          <Typeahead
          allowNew
          clearButton
          id="field-responding"
          labelKey='text'
          onChange={(e) => handleRespondingPartyChange(e)}
          onInputChange={handleOnRespondingInputChange}
          selected={(respondingPartyName && [respondingPartyName]) || []}
          size="sm"
          options={defendantOptions || []}
          open={showRespondingMenu}
          ref={respondTypeaheadRef}
        /> 
        </Form.Group>
        </div>
    )
  }

  function renderForm() {

    if (!showEdit) {
      return (
        <>
          {!_case ? (
            <Loading isLoading={!_case} text="Loading case information..." />
          ) : (
              <>
                <div className="mt-3">
                  <p className="label">Case</p>
                  <p>
                    {_case?.shortTitle}{" "}
                    <Button
                    size="sm"
                    variant="link"
                    onClick={() => handleCaseChangeClick()}
                  >
                    Change
                  </Button>
                  </p>
                </div>
                  {_case?.clientId === client?.id && (
                    <div>
                      <p className="label">Client</p>
                        <p>{client?.name ?? "-"}</p>
                      </div>
                  )}
                  <div>
                    <p className="label">Case Number</p>
                    <p>{_case?.caseNumber ?? "-"}</p>
                  </div>
                  <div>
                    <p className="label">Judge</p>
                    <p>{_case?.judge ?? "-"}</p>
                  </div>
                </>
            )}
          </>
      );
    } else {
      return (
        <>
          {user?.externalConnection?.isActive && isExternalCasesLoading && !externalCases ? (
            <Loading isLoading={isExternalCasesLoading && !externalCases} text="Loading case information..."></Loading>
          ) : (
              <>
                <h3 className="venue-header ">State of {jurisdiction?.friendlyName}</h3>
                  <div className={showEdit ? `mb-4` : ''}>
                    <label htmlFor="venue-select">Venue</label>
                    <Form.Select
                    required
                    id="venue-select"
                    disabled={!showEdit || (_case && !!_case.id)}
                    value={_case?.venue ?? venue}
                    onChange={(event) => setVenue(event.currentTarget.value)}
                    defaultValue={""}
                  >
                      <option value="" disabled>Please Select</option>
                      {venues?.sort((a, b) => a.shortName!.localeCompare(b.shortName!)).map((venue, index) => (
                        <option key={index} value={venue.id}>
                          {venue.shortName}
                          </option>
                      ))}
                    </Form.Select>
                  </div>
                  <ExternalPartnerConnection />
                  <div className={styles.caseSwitchToggle}>
                    <ButtonGroup className={styles.bpToggleTxtSwitch}>
                      {caseActionTypes.map((type, idx) => (
                        <ToggleButton
                          key={idx}
                          id={`radio-${idx}`}
                          className={caseAction === type.value
                            ? styles.btnChecked
                            : ""}
                          type="radio"
                          name="radio"
                          value={type.value}
                          checked={caseAction === type.value}
                          onChange={handleToggleChange}
                        >
                            {type.name}
                          </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </div>
                  {!isCurClientMatch && renderClientPosFields()}
                  <h4 className="venue-header mt-2">Select the Propounding Party</h4>
                  {renderPropoundFields()}
                  {!isCurClientMatch && (
                    <div className={styles.swapButtonWrapper}>
                      <div className={styles.swapButtonLine} />
                        <SwapIcon
                        className={styles.swapButton}
                        color="#4d5758"
                        title="Swap Parties"
                        onClick={swapParties}
                      />
                        <div className={styles.swapButtonLine} />
                      </div>
                  )}
                  <h4 className="venue-header">Select the Responding Party</h4>
                  {renderRespondingFields()}
                  <div className={styles.swapButtonWrapper}>
                    <div className={styles.swapButtonLine} />
                  </div>
                  <label htmlFor="case">Case</label>
                  <div className="mb-3" style={{ minHeight: "40px" }}>
                    <Typeahead
                    className={
                      _case && user?.externalConnection?.isActive
                        ? _case.integration?.partner
                          ? styles[`external-partner-${_case.integration.partner}`]
                          : styles[`external-partner-none`]
                        : ''
                    }
                    onChange={(e) => handleCaseChange(e)}
                    onInputChange={handleOnInputChange}
                    selected={(_case && [_case].map(c => new CombinedCaseSearch(c.shortTitle ?? c.title ?? '', buildSearchString(c), c.integration?.partner, c.integration?.identifier ?? undefined, c.id))) || []}
                    clearButton
                    filterBy={filterCallback}
                    flip={false}
                    isInvalid={!_case}
                    id="case"
                    emptyLabel={caseAction === "1" && "No matches found, this will create a new case."}
                    //
                    onFocus={caseAction === "1" ? () => setShowMenu(false) : () => setShowMenu(true)}
                    options={sortedCases}
                    open={showMenu}
                    size="sm"
                    placeholder={caseAction === "1" ? "Create a new case..." : "Find an existing case..."}
                    labelKey="title"
                    renderMenu={(results: any, menuProps: RenderMenuProps, state: any) => (
                      <CustomMenu results={results} menuProps={menuProps} state={state} showSourceLogos={!!externalCases?.length} />
                    )}
                    ref={caseTypeaheadRef}
                    isLoading={isExternalLoading}
                  />
                  </div>
                  {/* Make case # field required for any doc type except pleadings */}
                  <div className="mb-3">
                    <Form.Group>
                      <label htmlFor="case-number">Case Number</label>
                      <Form.Control
                      required={documentType !== DocumentType.Pleading ? true : false}
                      disabled={!!caseNumber && isExistingCase}
                      id="case-number"
                      size="sm"
                      value={_case?.caseNumber ?? caseNumber}
                      onChange={(event) => setCaseNumber(event.currentTarget.value)}
                    />
                    </Form.Group>
                  </div>
                  <div>
                    <label htmlFor="judge">Judge</label>
                    <Form.Control
                    value={_case?.judge ?? judge}
                    disabled={!!(_case?.judge ?? judge) && isExistingCase}
                    id="judge"
                    size="sm"
                    onChange={(event) => setJudge(event.currentTarget.value)}
                  />
                  </div>
                </>
            )}
          </>
      )
    }

  }

  const sortedCases: CombinedCaseSearch[] = mergeCases(cases, externalCases).sort((a, b) => {
    // Prioritize cases with a defined "partner" to the top
    if (a.partner && !b.partner) {
      return -1;
    } else if (!a.partner && b.partner) {
      return 1;
    }

    // Prioritize new cases (cases without an ID) to the top
    const caseIdA = a.internalId || a.externalId || "";
    const caseIdB = b.internalId || b.externalId || "";
    if (!caseIdA && caseIdB) {
      return -1;
    } else if (caseIdA && !caseIdB) {
      return 1;
    }

    // Continue with the regular alphabetical sort by title
    const shortTitleA = a.title || "";
    const shortTitleB = b.title || "";
    return shortTitleA.localeCompare(shortTitleB);
  });

  return (
    <div id="case-client-confirm">
      {renderForm()}
    </div>
  );
}

export default ReviewPageOnePd;
