import { Case, CommunicationStatus, CommunicationSource, CredentialResult, DocumentSubType, ViewCommunication } from 'briefpoint-client';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { DocumentClient, GetDocumentSubTypeShortName } from '../../services/DocumentService';
import { formatShortDate } from '../../utils/dateUtils';
import DocumentActionsMenu from './DocumentActionsMenu';
import { DocumentIcon, formatLocalDate, DueSoon, NewComm } from './DocumentList';
import React from 'react';
import { ChevronDown } from 'react-bootstrap-icons';
import './DocumentRowWithReview.scss';
import LinkandCodeModal from 'components/Modals/LinkandCodeModal';
import ReminderEmailModal from 'components/Modals/ReminderEmailModal';
import { useCredentialApi } from 'hooks/useApi';
import StartOverWarningModal from 'components/Modals/StartOverWarning';
import { Action } from 'components/CaseManagement/DocumentsTable';

export function DocumentRowWithReviewClient({
  doc, _case, archiveDocument, editDueDate, setShowSetCase, downloadForm, downloadRequest, createReview, communication, removeClientAccess, key, propoundingParty, respondingParty
}: {
  doc: DocumentClient;
  _case?: Case;
  archiveDocument: (document: DocumentClient) => Promise<void>;
  editDueDate?: Dispatch<SetStateAction<DocumentClient | undefined>>;
  setShowSetCase?: Dispatch<SetStateAction<DocumentClient | undefined>>;
  downloadForm: (document: DocumentClient, formId: string, formTypeName: string) => Promise<void>;
  downloadRequest: (document: DocumentClient) => Promise<void>;
  createReview?: (document: DocumentClient) => void;
  communication?: ViewCommunication;
  removeClientAccess: (communication: ViewCommunication) => Promise<void>;
  key: string;
  propoundingParty: string;
  respondingParty: string;
}) {
  const showReminderAfterDays = 5;
  const [reviewExpanded, setReviewExpanded] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showStartOverModal, setShowStartOverModal] = useState(false);

  const credentialApi = useCredentialApi();
  const [credential, setCredential] = useState<CredentialResult | undefined>();

  const loadCreds = useCallback(async (clientId: string) => {
    if (clientId) {
      const creds = await credentialApi.credentialCreate({ clientId: clientId });
      setCredential(creds);
    }
  }, [credentialApi]);

  useEffect(() => {
    if (communication?.sharedWithObjectId && (reviewExpanded || showEmailModal) && !credential) {
      loadCreds(communication?.sharedWithObjectId);
    }
  }, [communication?.sharedWithObjectId, credential, loadCreds, reviewExpanded, showEmailModal]);

  function getDaysPassed(timeSince: Date | undefined | null): number {
    if (!timeSince) {
      return -1;
    }
    const timeDiff = Math.abs(Date.now() - timeSince.getTime());
    const daysPassed = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysPassed;
  }

  function sendReminder() {
    setShowEmailModal(true);
  }

  function getReviewText(communication: ViewCommunication | undefined): string {
    if (!communication) {
      return "";
    }

    const formatDate = (date: Date | null | undefined): string => {
      if (!date) {
        return "";
      }
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${month}/${day}`;
    };

    switch (communication.status) {
      case CommunicationStatus.Sent:
        return `Review Requested ${formatDate(communication.lastActivityDate)}`;
      case CommunicationStatus.Open:
        return `Document Opened ${formatDate(communication.lastActivityDate)}`;
      case CommunicationStatus.InProgress:
        return `New Response ${formatDate(communication.lastActivityDate)}`;
      case CommunicationStatus.ChangesRequested:
        return `Changes Requested on ${formatDate(communication.lastActivityDate)}`;
      case CommunicationStatus.Complete:
        return `Review Completed ${formatDate(communication.lastActivityDate)}`;
      case CommunicationStatus.Pending:
        return `Draft Started ${formatDate(communication.lastActivityDate ?? communication.createdDate)}`;
      default:
        return "";
    }
  }

  const viewed = [
    CommunicationStatus.Open,
    CommunicationStatus.InProgress,
    CommunicationStatus.Complete,
    CommunicationStatus.ChangesRequested,
    CommunicationStatus.Closed
  ]

  const dontShowDropDown = [
    CommunicationStatus.Open,
    CommunicationStatus.InProgress,
  ]

  const showReminderEmail = [
    CommunicationStatus.Sent,
    CommunicationStatus.InProgress,
    CommunicationStatus.Open,
  ]

  const timePassed = getDaysPassed(communication?.lastActivityDate);
  let showReviewOption = true;
  if (communication?.status && dontShowDropDown.includes(communication?.status)) {
    showReviewOption = false;
  }
  //atm we dont support frogs
  if (doc?.info?.documentSubType === DocumentSubType.FormInterrogatorries) {
    showReviewOption = false;
  }

  const clientResponseNumber = communication?.externalCommunicationItems?.filter(ci => ci.source === CommunicationSource.Client)?.length ?? 0;
  const canSendReminderEmail = (communication?.status && showReminderEmail.includes(communication.status)) ?? false;

  let removeBtnText = communication?.status === CommunicationStatus.Pending ? "Delete" : "Start Over";

  return (
    <React.Fragment>
      <ReminderEmailModal
        isShow={showEmailModal}
        onClose={() => setShowEmailModal(false)}
        clientId={communication?.sharedWithObjectId!}
      />
      <tr key={key ?? doc.id} style={reviewExpanded ? { borderBottom: "hidden" } : {}}>
        <td>
          <DocumentIcon extension="pdf" />
        </td>
        <td className='name'>{doc.name?.replace('.pdf', '').replace('.PDF', '')}</td>
        <td>{doc.info?.documentSubType && GetDocumentSubTypeShortName(doc.info.documentSubType, doc.info.jurisdiction ?? '')}</td>
        <td>{formatShortDate(doc.uploadDate)}</td>
        <td>{propoundingParty}</td>
        <td>{respondingParty}</td>
        {editDueDate && <>
          <td>{formatLocalDate(doc.info?.dueDateOverridden || doc.info?.dueDate || undefined) || <Button className='set-dueDate-button' variant='link' onClick={() => editDueDate(doc)}>Set Due Date</Button>}</td>
          <td>{DueSoon(doc.info?.dueDateOverridden || doc.info?.dueDate || undefined)}</td></>}
        <td>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ margin: "0px" }}>
              {NewComm(communication, doc)}
              {getReviewText(communication)}{" "}
              {!!communication && (
                <ChevronDown onClick={() => setReviewExpanded(!reviewExpanded)} />
              )}
            </p>
            {timePassed > showReminderAfterDays && canSendReminderEmail && (
              <div className="activity-reminder-wrapper">
                Last activity {timePassed} days ago. <span className="activity-reminder-txt" onClick={() => setShowEmailModal(true)}> Send client a reminder? </span>
              </div>
            )}
          </div>
        </td>
        <td className='actions-cell'>{Action(doc)}</td>
        <td className='kebab-cell'>
          <DocumentActionsMenu
            document={doc}
            _case={_case}
            archiveDocument={archiveDocument}
            editDueDate={editDueDate}
            setShowSetCase={setShowSetCase}
            downloadForm={downloadForm}
            downloadRequest={downloadRequest}
            createReview={showReviewOption ? createReview : undefined}
            sendReminder={canSendReminderEmail ? sendReminder : undefined} />
        </td>
      </tr>
      {reviewExpanded && <tr style={{ borderTop: "none" }}>
        <td colSpan={editDueDate ? 10 : 8}>
          <div className='client-activity-row-container'>
            <div className='client-activity-header'>
              Client Activity Details
              <hr className='client-activity-hr' />
            </div>
            <div className='client-activity-content'>
              <table className='client-activity-table'>
                <thead>
                  <tr>
                    <th className='client-activity-th'>Number of Questions</th>
                    <th className='client-activity-th'>Viewed By Client?</th>
                    <th className='client-activity-th'>Client Answered</th>
                  </tr>
                  <tr className='client-activity-tr'>
                    <td style={{ paddingTop: "0px" }}>{communication?.communicationInfo?.requestedReviewIds?.length}</td>
                    <td style={{ paddingTop: "0px" }}>{communication?.status && viewed.includes(communication.status) ? "Yes" : "No"}</td>
                    <td style={{ paddingTop: "0px" }}>{clientResponseNumber}</td>
                  </tr>
                </thead>
              </table>
              <Button className='remove-client-access-btn'
                onClick={() => {
                  if (clientResponseNumber !== 0) {
                    setShowStartOverModal(true);
                  } else {
                    removeClientAccess(communication!);
                    setReviewExpanded(false);
                  }

                }}>
                {removeBtnText}
              </Button>
              <StartOverWarningModal
                isShow={showStartOverModal} onClose={
                  (success: boolean) => {
                    if (success) {
                      removeClientAccess(communication!);
                      setReviewExpanded(false);
                    }
                    setShowStartOverModal(false);
                  }}
              />
              {communication?.status === CommunicationStatus.Pending ?
                <Button className='view-link-code-btn'
                  onClick={() => { createReview!(doc) }}>
                  Continue
                </Button>
                :
                <Button className='view-link-code-btn'
                  onClick={() => { setShowLinkModal(true) }}>
                  View Link and Code
                </Button>
              }
              {<LinkandCodeModal
                isShow={showLinkModal}
                onClose={() => { setShowLinkModal(false) }}
                clientId={communication?.sharedWithObjectId ?? undefined}
                credential={credential}
              />}
            </div>
          </div>
        </td>
      </tr>}
    </React.Fragment>
  );
}
