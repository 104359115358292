import { Client } from 'briefpoint-client';
import { Button, Row, Table } from 'react-bootstrap';
import { getCaseSourceLogo } from 'utils/ExternalPartner/utils';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';

interface Props {
  addClient: () => void;
  clients: Client[] | undefined,
  showIfEmpty: boolean
}

export default function ClientTable({ addClient, clients, showIfEmpty = false }: Props) {
  return (
    <Loading isLoading={clients === undefined}>
      {(showIfEmpty || (clients?.length !== undefined && clients?.length > 0))
        && (<div>
          {clients?.length ?
            <>

              <Row>
                <Table striped hover style={{ marginLeft: '12px' }}>
                  <thead>
                    <tr className="table-header">
                      <th className="table-header-cell" scope="">
                        Name
                      </th>
                      <th className="table-header-cell" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients?.map(c => {
                      return (
                        <tr className='table-row' key={c.id}>
                          <td className='table-cell'><span style={{ width: '18px', display: 'inline-block'}}>{getCaseSourceLogo(c.integration?.partner)}</span> {c.name}</td>
                          <td className='actions-cell'><Link className="btn btn-outline-primary btn-sm wide" to={{
                            pathname: `/clients/${c.id}`,
                            state: {
                              client: c
                            }
                          }}>View Client</Link></td>
                        </tr>)
                    })}
                  </tbody>
                </Table>
              </Row>
            </>
            : <div>No Clients <Button variant='link' onClick={addClient}>Add one now</Button>  or just start by uploading a document.</div>}
        </div>)}
    </Loading>
  );
}
