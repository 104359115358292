import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { Check } from 'react-bootstrap-icons';
import { classes } from 'utils';

interface Props {
  numSteps: number;
  activeIndex: number;
  completedStepIndexes: Set<number>;
  onStepClick: (index: number) => void;
}

export default function SourceDocumentReviewSteps({
  numSteps,
  activeIndex,
  completedStepIndexes,
  onStepClick,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<Array<HTMLDivElement | HTMLButtonElement | null>>([]);

  const centerStep = (stepIndex: number) => {
    const element = itemsRef.current[stepIndex];
    const scroll = ref.current;

    if (element && scroll) {
      scroll.scrollTo(element.offsetLeft - scroll.offsetLeft - scroll.clientWidth / 2, 0);
    }
  };

  useEffect(() => {
    centerStep(activeIndex);
  }, [activeIndex]);

  return (
    <div ref={ref} id="source-document-review-steps">
      {[...Array(numSteps)].map((e, i) => (
        <div key={i} ref={(el) => (itemsRef.current[i] = el)} className={classNames({ 'step-slide': i !== numSteps - 1 })}>
          <button
            className={classes('step-button', {
              active: activeIndex === i,
            })}
            onClick={() => onStepClick(i)}
          >
            {i + 1}
          </button>
          {completedStepIndexes.has(i) && <Check className="step-complete-check" />}
        </div>
      ))}
    </div>
  );
}
