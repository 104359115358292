import { Case, Client, Role, User } from 'briefpoint-client';
import AddEditCaseModal from 'components/CaseManagement/AddEditCaseModal';
import AddEditClientModal from 'components/CaseManagement/AddEditClientModal';
import CaseTable from 'components/CaseManagement/CaseTable';
import Loading from 'components/Loading';
import { useCaseApi, useClientApi, useCredentialApi, useTagApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import useFirm from 'hooks/useFirm';
import { getCaseSourceLogo } from 'utils/ExternalPartner/utils';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import './styles.scss';
import { TrashFill } from 'react-bootstrap-icons';
import { Modal } from 'components/Modals';
import useConfig, { BRIDGE_FF } from 'hooks/useConfig';

type ClientParams = {
  id: string;
};

export default function ClientPage() {
  const { user, firm } = useAuth()!;
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<ClientParams>();
  const clientApi = useClientApi();
  const caseApi = useCaseApi();
  const tagApi = useTagApi();
  const [users, , , ,] = useFirm(firm?.id, false);
  const [showAddCase, setShowAddCase] = useState(false);
  const [showEditClient, setShowEditClient] = useState(false);
  const [cases, setCases] = useState<Case[] | undefined>();
  const [client, setClient] = useState<Client>((location.state as any)?.client);
  const [oldClientEmail,] = useState<Client>((location.state as any)?.client.contactEmail);
  const [search, setSearch] = useState('');
  const [tags, setTags] = useState<Map<number, string> | undefined>();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { jurisdictions } = useAuth()!;
  const credentialApi = useCredentialApi();
  const [, featureFlags] = useConfig();
  const useBridge = featureFlags()[BRIDGE_FF] ? featureFlags()[BRIDGE_FF](user, firm) : false;
  const [emailError, setEmailError] = useState<string | undefined>();

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setShowEditClient(params.get("editClient") === 'true');
  }, [location.search]);

  const loadClient = useCallback(async (user: User | undefined, client: Client | undefined) => {
    if (user && user.firmId && !client) {
      const client = await clientApi.clientGet({ firmId: user.firmId!, id });
      setClient(client);
    }
  }, [clientApi, id]);

  useEffect(() => {
    loadClient(user, client);
  }, [user, loadClient, client]);

  const loadCases = useCallback(async (user: User | undefined) => {
    if (user && user.firmId && id) {
      const cases = await caseApi.caseGetAll({ firmId: user.firmId!, clientId: id, usersCasesOnly: !user?.role?.includes(Role.FirmAdmin) });
      setCases(cases);
    }
  }, [caseApi, id]);

  useEffect(() => {
    loadCases(user);
  }, [user, loadCases]);

  useEffect(() => {
    async function loadTags() {
      const tagsList = new Map<number, string>();

      const loadedTags = await tagApi.tagFindTags({});

      loadedTags.forEach(t => tagsList.set(t.id, t.name));
      setTags(tagsList);
    };
    if (!tags) {
      loadTags();
    }
  }, [tagApi, tags]);

  async function AddCase(_case: Case) {
    const newCase = await caseApi.casePost({ firmId: user?.firmId!, clientId: id, _case });

    if (newCase) {
      if (cases) {
        setCases(c => [...c!, newCase]);
      } else {
        setCases([newCase]);
      }
    }

    closeAddCase();
  }

  function closeAddCase() {
    setShowAddCase(false);
  }

  async function saveClient(c: Client) {
    try {
      if (useBridge && !!c?.contactEmail?.trim()?.length && oldClientEmail !== c?.contactEmail) {
        await credentialApi.credentialSync({ clientId: c.id!, newEmail: c.contactEmail! });
      }
      await clientApi.clientPut({ firmId: user?.firmId!, id: c.id!, client: c });
      setEmailError(undefined);
      setClient(c);
      setShowEditClient(false);
    }
    catch (error: any) {
      if (error.status === 409) {
        setEmailError("This email is already in use with another client. Please use another one.");
      }
      else {
        setEmailError("Something has gone wrong please try again, or reload the page.");
      }
    }
  }

  async function handleClientDelete() {
    await clientApi.clientDelete({ firmId: user?.firmId!, id: client.id! });

    history.push("/clients");
  }

  const filteredCases = cases?.filter(x => {
    if (!search) return true;

    const split = (x.title || '').concat(x.shortTitle || '').split(' ');

    for (let i = 0; i < split?.length; i++) {
      if (split[i].toLowerCase().startsWith(search.toLowerCase())) {
        return true;
      }
    }

    return false;
  });

  return (
    <>
      <Container className="page-container">
        <Loading isLoading={client === undefined && cases === undefined && jurisdictions === undefined && users === undefined}>
          <Row>
            <Col>
              <div className="mb-4 bread-crumbs"><Link to={'/clients'}>Clients</Link> {`> ${client?.name}`}</div></Col>
            <Col className='actions-cell'>
              <Button variant="primary" onClick={() => setShowAddCase(true)}>
                Add New Case
              </Button>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <div className='client-name-header'><p><span style={{ width: '18px', display: 'inline-block' }}>{getCaseSourceLogo(client?.integration?.partner)}</span> {client?.name}</p></div>
              <Button variant="outline-secondary fs-6" onClick={() => setShowEditClient(true)}>
                Edit Client Details
              </Button>
              {cases && !cases.length && <TrashFill style={{ cursor: "pointer", marginLeft: "4px" }} onClick={() => setShowDeleteConfirm(true)} title='Delete Client'>Delete</TrashFill>}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs="4">
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">⌕</div>
                </div>
                <Form.Control
                  value={search}
                  className="form-control defense-search-input"
                  placeholder="Search cases"
                  onChange={(e) => setSearch(e.target.value.toLowerCase())}
                />
              </div>
            </Col>
          </Row>
          {((filteredCases?.length ?? 0) > 5) &&
            (<div><CaseTable cases={filteredCases?.slice().sort((a, b) => {
              if (a.lastActivity && b.lastActivity) {
                return a.lastActivity > b.lastActivity ? -1 : 1;
              }
              else if (a.lastActivity) {
                return -1;
              }
              else if (b.lastActivity) {
                return 1;
              }
              else {
                return 0;
              }
            }).slice(0, 5)} addCase={() => setShowAddCase(true)} client={client} users={users} jurisdictions={jurisdictions} showIfEmpty={false} />
              <span>&nbsp;&nbsp;</span>
              <h2>All Cases</h2>
            </div>)}
          <CaseTable cases={filteredCases?.slice().sort((a, b) => {
            if (a.shortTitle && b.shortTitle) {
              return a.shortTitle.localeCompare(b.shortTitle);
            }
            else if (a.shortTitle) {
              return -1;
            }
            else if (b.shortTitle) {
              return 1;
            }
            else {
              return 0;
            }
          })} addCase={() => setShowAddCase(true)} client={client} users={users} jurisdictions={jurisdictions} showIfEmpty={true} />
          {client && firm &&
            <>
              <AddEditClientModal title='Edit Client' confirm='Save' isShow={showEditClient} onClose={() => setShowEditClient(false)} onConfirm={saveClient} client={client} users={users} emailError={emailError} />
              <AddEditCaseModal availableTags={tags} setAvailableTags={setTags} title='Add Case' confirm='Add' isShow={showAddCase} onClose={() => setShowAddCase(false)} onConfirm={AddCase} users={users} jurisdictions={jurisdictions!} client={client} firm={firm} />
            </>}
        </Loading>
      </Container>
      <Modal onClose={() => setShowDeleteConfirm(false)}
        onConfirm={handleClientDelete}
        show={showDeleteConfirm}
        confirmText='Yes, Delete'
        title='Delete Client' >
        Are you sure you want to delete this Client? This action cannot be undone.
      </Modal>
    </>
  );
}
