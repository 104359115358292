import './Toggle.scss';

export default function Toggle({
  checked,
  disabled,
  label,
  alert,
  onToggle
}: {
  checked: boolean,
  checkedName: string,
  unCheckedName: string,
  disabled: boolean,
  label: string | JSX.Element | Element,
  alert: string | undefined,
  onToggle: () => void
}) {
  return (
    <div className="toggle-container">
      <div className="toggle">
        <div id='button-body'
          role="button"
          className={`pill ${checked ? 'active-pill' : ''} ${disabled ? 'toggle-disabled' : ''
            }`}
          onClick={onToggle}
        >
          <div className={`pip ${checked ? 'active-pip' : ''}`} />
        </div>
        <label htmlFor='button-body'>{label} </label>
        {alert && <div className='toggle-alert'>{alert}</div>}
      </div>
    </div>
  );
}
