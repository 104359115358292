import Loading from 'components/Loading';
import useDocumentDetail, { useDocumentFormInterrogatories } from 'hooks/useDocumentDetail';
import { Container } from 'react-bootstrap';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import InterrogatoriesSelect from './InterrogatoriesSelect';
import { IntLi } from './InterrogatoriesList';
import { useCallback, useEffect, useState } from 'react';
import { Case, Client, CommunicationStatus, CommunicationType, DocumentSubType, FormInt, Interrogatory, ObjectTypeEnum, ViewCommunication } from 'briefpoint-client';
import { useCaseApi, useClientApi, useCommunicationApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import { User } from '@auth0/auth0-react';
import { DocumentDetailClient } from 'services/DocumentService';
import useConfig, { BRIDGE_FF } from 'hooks/useConfig';
import { getRequestTypeSingular } from 'screens/DocumentWizard/SpecialInterrogatoriesStep/Selections/utils';

export function sortCommunications(a: ViewCommunication, b: ViewCommunication) {
  const sortOrder: CommunicationStatus[] = [
    CommunicationStatus.Complete,
    CommunicationStatus.ChangesRequested,
    CommunicationStatus.InProgress,
    CommunicationStatus.Open,
    CommunicationStatus.Pending,
    CommunicationStatus.Sent,
    CommunicationStatus.Unknown,
    CommunicationStatus.Closed,
    CommunicationStatus.Deleted
  ];
  const indexA = sortOrder.indexOf(a.status ?? CommunicationStatus.Unknown);
  const indexB = sortOrder.indexOf(b.status ?? CommunicationStatus.Unknown);

  if (indexA - indexB === 0) {
    return b.lastActivityDate?.getTime() ?? b.createdDate!.getTime() - (a.lastActivityDate?.getTime() ?? a.createdDate!.getTime());
  }
  return indexA - indexB;
}

export default function ClientFeedback() {
  const history = useHistory();
  const { documentId } = useParams<{ documentId: string }>();
  const [document] = useDocumentDetail(documentId);
  const [intData, setIntData] = useState<IntLi[]>([]);
  const clientApi = useClientApi();
  const communicationApi = useCommunicationApi();
  const location = useLocation();
  const { user, firm, defaultJurisdiction } = useAuth()!;
  const caseApi = useCaseApi();
  const formInterrogatories = useDocumentFormInterrogatories(document?.info?.documentSubType);
  const [client, setClient] = useState<Client>((location.state as any)?.client);
  const [caseData, setCaseData] = useState<Case | undefined>();
  const [activeCommunication, setActiveCommunication] = useState<ViewCommunication>();
  const [, featureFlags] = useConfig();
  const useBridge = featureFlags()[BRIDGE_FF] ? featureFlags()[BRIDGE_FF](user, firm) : false;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  //todo:gray out when existing communications are in open / in progress for document?

  const loadClient = useCallback(async (user: User, doc: DocumentDetailClient) => {
    if (doc.clientId) {
      const client = await clientApi.clientGet({ firmId: user.firmId!, id: doc.clientId });
      setClient(client);
    }
  }, [clientApi]);

  const loadCase = useCallback(async (user: User, doc: DocumentDetailClient) => {
    if (doc.caseId) {
      const cases = await caseApi.caseGet({ firmId: user.firmId, id: doc.caseId });
      setCaseData(cases);
    }
  }, [caseApi]);

  const loadOrCreateCommunication = useCallback(async (doc: DocumentDetailClient, cData: Case) => {


    const coms = await communicationApi.communicationGetCommunicationsForObject({ objectId: doc.id, type: ObjectTypeEnum.Document });
    const filteredCommunications = coms.filter(c =>
      c.status !== CommunicationStatus.Closed
    ).sort(sortCommunications);
    if (filteredCommunications.length === 0) {
      var newCom = await communicationApi.communicationCreateClientCommunication(
        {
          createClientCommunication: {
            objectType: ObjectTypeEnum.Document,
            objectId: doc.id,
            caseId: cData.id,
            communicationType: CommunicationType.Review,
            status: CommunicationStatus.Pending,
            communicationInfo: {
              title: getRequestTypeSingular(doc.info?.documentSubType!, defaultJurisdiction.name ?? "California"),
              caseTitle: cData.title,
              requestedReviewIds: intData.filter(d => !!d.selected && !!d.id).map(d => d.id ?? -1).filter(d => d !== -1)
            }
          }
        }
      );
      setActiveCommunication(newCom);
    }
    else {
      //todo: set readonly here is open or in progress
      const updatedIntData = intData.map((intLi) => {
        const isSelected = filteredCommunications[0].communicationInfo?.requestedReviewIds?.includes(intLi.id ?? -1) ?? false;
        const comData = filteredCommunications[0].externalCommunicationItems?.find(itm => itm.objectId === intLi.id?.toString() && itm.createdBy === user?.id)?.communication;
        return {
          ...intLi,
          selected: isSelected,
          clientMessage: comData ?? undefined
        };

      });
      setActiveCommunication(filteredCommunications[0]);
      setIntData(updatedIntData);
    }
  }, [communicationApi, defaultJurisdiction.name, intData, user?.id]);

  //load document question data once document has loaded 
  useEffect(() => {
    if (document?.info?.documentSubType === DocumentSubType.FormInterrogatorries) {
      const relevantQuestions = (formInterrogatories?.filter(frog => frog.form === document?.info?.formIntType) ?? []).map(rq => {
        let idx = document?.interrogatories?.findIndex(rog => rog.text === rq.id)
        if (idx !== -1) {
          (rq as IntLi).selected = true;
          rq.id = document.interrogatories[idx].id.toString();
        }
        return rq;
      }).filter(q => (q as IntLi).selected)
        .map(q => mapFormIntToIntLi(q));
      setIntData(relevantQuestions);
    }
    else {
      const relevantQuestions = document?.interrogatories?.map((q) =>
        mapToIntLi(q)
      ) ?? [] as IntLi[];
      setIntData(relevantQuestions);
    }
  }, [document, formInterrogatories, setIntData]);

  useEffect(() => {
    if (user && document) {
      loadClient(user, document);
    }
  }, [document, user, loadClient]);

  useEffect(() => {
    if (user && document) {
      loadCase(user, document)
    }
  }, [document, user, loadCase]);

  useEffect(() => {
    if (document && caseData && intData.length && !activeCommunication) {
      loadOrCreateCommunication(document, caseData)
    }
  }, [activeCommunication, caseData, document, intData.length, loadOrCreateCommunication]);

  const UpdateCom = useCallback(async () => {
    if (activeCommunication && activeCommunication.id) {
      setIsSubmitting(true);
      var com = await communicationApi.communicationUpdateCommunication({
        putClientCommunication: {
          sharedWithObjectId: activeCommunication.sharedWithObjectId,
          sharedWithObjectType: activeCommunication.sharedWithObjectType,
          status: CommunicationStatus.Pending,
          communicationInfo: {
            title: `${getEnumName(document?.info?.documentSubType ?? DocumentSubType.RequestsForProduction)}`,
            caseTitle: caseData?.title,
            requestedReviewIds: intData.filter(d => !!d.selected && !!d.id).map(d => d.id ?? -1).filter(d => d !== -1)
          }
        }, id: activeCommunication.id
      });
      setActiveCommunication(com);
      setIsSubmitting(false);
    }
  }, [activeCommunication, caseData?.title, communicationApi, document?.info?.documentSubType, intData]);

  const CreateComItem = useCallback(async (interrogatory: IntLi) => {
    if (activeCommunication && activeCommunication.id) {
      setIsSubmitting(true);
      const itm = await communicationApi.communicationAddCommunicationItem({
        postCommunicationItem: {
          objectType: ObjectTypeEnum.Interrogatory,
          objectId: interrogatory.id?.toString(),
          communication: interrogatory.clientMessage,
        }, id: activeCommunication.id
      });

      setActiveCommunication({
        ...activeCommunication,
        externalCommunicationItems: [
          ...(activeCommunication.externalCommunicationItems || []),
          itm
        ]
      });
      setIsSubmitting(false);
    }
  }, [activeCommunication, communicationApi]);

  const UpdateComItem = useCallback(async (interrogatory: IntLi, maxRetry: number) => {
    if (activeCommunication && activeCommunication.id) {
      setIsSubmitting(true);
      const comItemId = activeCommunication.externalCommunicationItems?.find(itm => itm.objectId === interrogatory.id?.toString())?.id;
      //api is not back yet, wait for it's respose
      if (!comItemId) {
        if (maxRetry > 0) {
          setTimeout(() => UpdateComItem(interrogatory, maxRetry - 1), 200);
        }
        setIsSubmitting(false);
        return;
      }

      const itm = await communicationApi.communicationPutCommunicationItem({
        putCommunicationItem: {
          id: comItemId,
          objectType: ObjectTypeEnum.Interrogatory,
          objectId: interrogatory.id?.toString(),
          communication: interrogatory.clientMessage,
        }, id: activeCommunication.id
      });

      setActiveCommunication({
        ...activeCommunication,
        externalCommunicationItems: [
          ...(activeCommunication.externalCommunicationItems || []),
          itm
        ]
      });
      setIsSubmitting(false);
    }
  }, [activeCommunication, communicationApi])

  //handle sync for communication items
  useEffect(() => {
    if (intData.some(d => d.action === 'cb')) {
      setIntData(intData.map(intD => intD.action === 'cb' ? { ...intD, action: undefined } : intD));
      UpdateCom();
    }
    else if (intData.some(d => d.action === 'text-create')) {
      for (const intD of intData) {
        if (intD.action === 'text-create') {
          CreateComItem(intD);
        }
      }
      setIntData(intData.map(intD => intD.action === 'text-create' ? { ...intD, action: undefined } : intD));
    }
    else if (intData.some(d => d.action === 'text-update')) {
      for (const intD of intData) {
        if (intD.action === 'text-update') {
          UpdateComItem(intD, 10);
        }
      }
      setIntData(intData.map(intD => intD.action === 'text-update' ? { ...intD, action: undefined } : intD));
    }

  }, [CreateComItem, UpdateCom, UpdateComItem, intData]);

  function mapToIntLi(obj: Interrogatory): IntLi {
    return {
      id: obj.id ?? undefined,
      documentId: obj.documentId,
      number: obj.number.toString() ?? undefined,
      text: obj.text ?? null,
      selected: true,
      action: undefined,
      includeTranslation: false,
      clientMessage: undefined,
    };
  }

  function getEnumName(subtype: DocumentSubType): keyof typeof DocumentSubType {
    return Object.keys(DocumentSubType).find(key => DocumentSubType[key as keyof typeof DocumentSubType] === subtype) as keyof typeof DocumentSubType;
  }

  function mapFormIntToIntLi(obj: FormInt): IntLi {
    return {
      id: parseInt(obj.id ?? "-1") ?? undefined,
      number: `${obj.section?.toString()}.${obj.number?.toString()}`,
      text: obj.text ?? null,
      selected: true,
      action: undefined,
      includeTranslation: false,
      clientMessage: undefined,
    };
  }

  async function onConfirmClientInfoClick() {
    if (activeCommunication?.id && !isSubmitting) {
      history.push(`/documents/${documentId}/client-feedback/${activeCommunication?.id}/confirm-client-info`);
    }
  }

  let questionType = "Interrogatory";

  switch (document?.info?.documentSubType) {
    case DocumentSubType.SpecialInterrogatorries:
    case DocumentSubType.FormInterrogatorries:
      questionType = "Interrogatory";
      break;
    case DocumentSubType.RequestsForAdmission:
    case DocumentSubType.RequestsForProduction:
      questionType = "Request";
      break;
  }

  const readOnly =
    (activeCommunication?.status === CommunicationStatus.Open)
    || (activeCommunication?.status === CommunicationStatus.InProgress)
    || (activeCommunication?.status === CommunicationStatus.ChangesRequested)
    || (activeCommunication?.status === CommunicationStatus.Complete);
  return (
    <Loading className="mt-3" isLoading={!document}>
      <Container className="page-container">
        <div className="mb-4 bread-crumbs">
          <Link to={'/documents'}>Document List</Link> {`> Request Client Response`}
        </div>
        {useBridge && (<InterrogatoriesSelect
          intData={intData}
          setFormIntData={setIntData}
          clientName={client?.name ?? ""}
          questionType={questionType}
          confirmClientInfoClick={() => { onConfirmClientInfoClick() }}
          readOnly={readOnly}
        />)}
      </Container>
    </Loading>
  );
}
