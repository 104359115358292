import { ExternalPartners, Jurisdiction, Preferences, User } from 'briefpoint-client';
import rg4js from 'raygun4js';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FirmClient, transformFirm } from 'services/FirmService';
import { useSessionStorage } from 'usehooks-ts';
import { useFirmApi, useJurisdictionApi, useUserApi } from './useApi';
import useMixpanel from './useMixpanel';
import { promiseRetry } from 'utils';
import { CaliforniaId } from 'components/CaseManagement/AddEditCaseModal';

interface AuthProvider {
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  getUser: () => Promise<User | undefined>;
  createUser: (useDefaultJurisdiction: boolean) => Promise<User | undefined>;
  updateUser: (user: User) => void;
  firm: FirmClient | undefined;
  setFirm: React.Dispatch<React.SetStateAction<FirmClient | undefined>>;
  updateFirm: (firm: FirmClient) => void;
  updatePreferences: (preferences: Preferences) => void;
  firmAddOffice: (firm: FirmClient, jurisdiction: string, state: string) => void;
  baseUser: User | undefined;
  jurisdictions: Jurisdiction[] | undefined; //TODO: this doesn't technically belong in "AuthProvider" but I couldn't figure out a better way to have this value cached for the life of the app
  defaultJurisdiction: Jurisdiction,
}

export const OnBehalfOfSessionKey = "on_behalf_of";
export const OnBehalfOfHeaderKey = "SA-ONBEHALF-OF";

const authContext = createContext<AuthProvider | null>(null);

// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
export function ProvideAuth({ children }: { children: React.ReactNode }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState<User>();
  const [baseUser, setBaseUser] = useState<User>();
  const [firm, setFirm] = useState<FirmClient>();
  const [onbehalfof] = useSessionStorage(OnBehalfOfSessionKey, undefined);
  const alreadyLoading = useRef<string | undefined>();
  const userApi = useUserApi();
  const firmApi = useFirmApi();
  const jurisdictionApi = useJurisdictionApi();
  const [, mixpanelIdentify] = useMixpanel()!;
  const [jurisdictions, setJurisdictions] = useState<Jurisdiction[]>();
  const jurisdictionsCall = useRef<Promise<Jurisdiction[]>>();
  const jurisdictionId = firm?.offices?.at(0)?.jurisdictions?.at(0)?.jurisdictionId;
  const defaultJurisdiction = (jurisdictions ?? []).find(jx => jx.id === jurisdictionId)
    ?? (jurisdictions ?? []).find(jx => jx.id === CaliforniaId)
    ?? (jurisdictions ?? [])[0];

  const getFirm = useCallback(
    async (user) => {
      if (user.firmId) {
        var firm = await firmApi.firmGet({ id: user.firmId });
        setFirm(transformFirm(firm));
      }
    },
    [firmApi]
  );

  const setRaygunUser = useCallback(async (user: User) => {
    mixpanelIdentify(user!.id);
    rg4js('setUser', {
      identifier: user?.id || '',
      isAnonymous: false,
      firstName: user?.firstName || '',
      fullName: (user?.firstName || '') + ' ' + (user?.lastName || '')
    });
  }, [mixpanelIdentify]);

  const handleGetUser = useCallback(async () => {
    var user = await userApi.userGet();
    if (user) {
      setUser(user);
      setBaseUser(user);
      setRaygunUser(user);
      await getFirm(user);
    }
    return user;
  }, [getFirm, userApi, setRaygunUser]);

  const handleCreateUser = useCallback(async (createDefaultJurisdiction: boolean) => {
    let partner: ExternalPartners | undefined;
    const externalPartnerQuery = sessionStorage.getItem("externalPartner");
    if (externalPartnerQuery) {
      let val = externalPartnerQuery as ExternalPartners;
      if (val && val in ExternalPartners) {
        partner = val;
      }
    }
    var user = await userApi.userPost({ useDefaultJurisdiction: createDefaultJurisdiction, partner });
    if (user) {
      setUser(user);
      setBaseUser(user);
      setRaygunUser(user);
      await getFirm(user);
    }
    return user;
  }, [getFirm, userApi, setRaygunUser]);

  const handleUpdateUser = useCallback(
    async (user: User) => {
      if (user) {
        var updatedUser = await userApi.userPut({ user });
        setUser(updatedUser);
        setRaygunUser(user);
      }
    },
    [userApi, setRaygunUser]
  );

  const handleUpdateFirm = useCallback(
    async (firm: FirmClient) => {
      if (firm) {
        var updatedFirm = await firmApi.firmPut({ id: firm.id, firm });
        setFirm(transformFirm(updatedFirm));
      }
    },
    [firmApi]
  );

  const handleFirmAddOffice = useCallback(
    async (firm: FirmClient, jurisdiction: string, state: string) => {
      if (firm) {
        const office = await firmApi.firmCreateOffice({ id: firm.id, createOfficeModel: { jurisdictionId: jurisdiction, state } });
        if (!firm.offices) {
          firm.offices = [];
        }
        firm.offices.push(office);
        setFirm({ ...firm });
      }
    },
    [firmApi]
  );

  const handleUpdatePreferences = useCallback(
    async (p: Preferences) => {
      if (p) {
        await userApi.userUpdatePreferences({ preferences: p });
        setUser(u => ({ ...u, preferences: p }));
      }
    },
    [userApi]
  );

  useEffect(() => {
    const loadUser = async (userId: string) => {
      const onbehalfof_user = await userApi.userGetUser({ userId });
      if (onbehalfof_user) {
        setUser(onbehalfof_user);
      }
    };
    if (onbehalfof) {
      if (user && user.id !== onbehalfof && alreadyLoading?.current !== onbehalfof) {
        alreadyLoading.current = onbehalfof;
        loadUser(onbehalfof);
      }
    } else if (user && baseUser && baseUser?.id !== user?.id) {
      alreadyLoading.current = undefined;
      setUser(baseUser);
    }
  }, [onbehalfof, baseUser, user, userApi, alreadyLoading]);

  useEffect(() => {
    const loadJxs = async () => {
      if (!jurisdictionsCall.current) {
        jurisdictionsCall.current = promiseRetry(() => jurisdictionApi.jurisdictionGet(), 5, 500);
      }

      const jxs = await jurisdictionsCall.current;
      setJurisdictions(x => {
        if (!x)
          return jxs;

        return x;
      });
    };

    if (user && !jurisdictions) {
      loadJxs();
    }
  }, [jurisdictionApi, jurisdictions, user]);

  return {
    user,
    setUser,
    getUser: handleGetUser,
    createUser: handleCreateUser,
    updateUser: handleUpdateUser,
    firm,
    setFirm,
    updateFirm: handleUpdateFirm,
    updatePreferences: handleUpdatePreferences,
    firmAddOffice: handleFirmAddOffice,
    baseUser,
    jurisdictions,
    defaultJurisdiction
  };
}
