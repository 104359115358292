import {
  Client,
  ContactInfoType,
  CredentialResult,
  User,
} from "briefpoint-client";
import { FirmClient } from "services/FirmService";

export const replaceDictionary = (
  client: Client | undefined,
  credential: CredentialResult | undefined,
  user: User | undefined,
  firm: FirmClient | undefined,
): { [key: string]: string } => {
  return {
    Bridge_Unique_Code: credential?.credential?.passcode ?? "",
    Client_Contact_Name:
      `${client?.contactFirstName ?? ""} ${client?.contactLastName ?? ""}`
        .trim() ?? "",
    Bridge_Secure_Platform_URL: `${process.env.REACT_APP_BRIDGE_URL}?firmcode=${encodeURIComponent(credential?.credential?.firmCode ?? "")
      }&email=${encodeURIComponent(client?.contactEmail ?? "")}`,
    Attorney_Name: `${user?.firstName} ${user?.lastName}`,
    Law_Firm_Name: firm?.name ?? "Law_Firm_Name",
    Attorney_Email:
      user?.contactInfo?.find((ci) => ci.type === ContactInfoType.Email)
        ?.value ??
      firm?.contactInfo?.find((ci) => ci.type === ContactInfoType.Email)
        ?.value ??
      "Attorney_Email",
    Attorney_Phone_Number:
      user?.contactInfo?.find((ci) => ci.type === ContactInfoType.Phone)
        ?.value ??
      firm?.contactInfo?.find((ci) => ci.type === ContactInfoType.Phone)
        ?.value ??
      "Attorney_Phone",
  };
};
