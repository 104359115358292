import { ContactInfo, ContactInfoType, User, UserInvite, UserType } from 'briefpoint-client';
import { useCallback, useEffect, useState } from 'react';
import { useFirmApi } from './useApi';

type UseFirmType = [
  User[] | null,
  UserInvite[] | null,
  () => Promise<void>,
  (email: string, type: UserType) => Promise<void>,
  (userId: string, activate: boolean) => Promise<void>,
];
export default function useUsers(firmId: string | undefined, loadInvites: boolean = true): UseFirmType {
  const [users, setUsers] = useState<User[] | null>(null);
  const [invites, setInvites] = useState<UserInvite[] | null>(null);
  const firmApi = useFirmApi();

  const loadUsers = useCallback(async () => {
    if (firmId) {
      const usersCall = firmApi.firmGetUsers({ id: firmId });
      const invitesCall = loadInvites ? firmApi.firmGetInvites({ id: firmId }) : Promise.resolve();

      const results = { users: await usersCall, invites: await invitesCall };

      setUsers(results.users);
      if (loadInvites) {
        setInvites(results.invites!);
      }
    }
  }, [firmApi, firmId, loadInvites]);

  const sendInvite = useCallback(async (email: string, type: UserType) => {
    if (users?.find(u => u.contactInfo?.find((x: ContactInfo) => x.type === ContactInfoType.Email && x.value?.toLowerCase() === email.toLowerCase()))) {
      return;
    }
    await firmApi.firmInviteUser({ id: firmId!, inviteUserToFirm: { emailAddress: email, userType: type } });
    setInvites(await firmApi.firmGetInvites({ id: firmId! }));
  }, [firmApi, firmId, users]);

  const activateDeactivateUser = useCallback(async (userId: string, activate: boolean) => {
    if (activate) {
      await firmApi.firmActivateUser({ id: firmId!, userId: userId });
    } else {
      await firmApi.firmDeactivateUser({ id: firmId!, userId: userId });
    }
    loadUsers();
  }, [firmApi, firmId, loadUsers]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return [users, invites, loadUsers, sendInvite, activateDeactivateUser];
}
