import {
  Body,
  Cell,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
  TableNode,
} from '@table-library/react-table-library';
import { HeaderCellSort, useSort } from '@table-library/react-table-library/sort';
import {
  ContactInfo,
  ContactInfoCategory,
  ContactInfoType,
  User,
  UserInvite,
  UserType,
} from 'briefpoint-client';
import { useEffect, useState } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { PersonPlus } from 'react-bootstrap-icons';
import InviteModal from './InviteModal';
import ActivateModal, { ActivateModel } from './ActivateModal';
import './FirmUserList.scss';

function getWidth(width: number) {
  return {
    width: `${width}%`,
    minWidth: `${width}%`,
  };
}

function Toggle({
  isCanonicallyInactive,
  canonicalDeactivationDate,
  onActivateDeactivate,
}: {
  isCanonicallyInactive: boolean;
  canonicalDeactivationDate: string;
  onActivateDeactivate: () => void;
}) {
  // A) we expect pill toggles to update instantly so we update local state instantly,
  // initialized by canonical value
  const [isLocallyInactive, setIsLocallyInactive] = useState(isCanonicallyInactive);

  useEffect(() => {
    // B) however, we don't want a inconsistent UI should the call fail, so let's
    // register a timeout to eventually correct the local state
    const timeout = setTimeout(() => {
      if (isLocallyInactive !== isCanonicallyInactive) {
        setIsLocallyInactive(isCanonicallyInactive);
      }
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLocallyInactive, isCanonicallyInactive]);

  useEffect(() => {
    // C) inversely, if canonical is somehow updated, we need a way of correcting local state
    setIsLocallyInactive(isCanonicallyInactive);
  }, [isCanonicallyInactive]);

  const onToggle = () => {
    // D) if we're attempting a toggle and we don't have the correct local state, bail
    if (isLocallyInactive !== isCanonicallyInactive) {
      return;
    }
    setIsLocallyInactive((x) => !x);
    onActivateDeactivate();
  };
  let message = '';
  if (isCanonicallyInactive && isLocallyInactive && !!canonicalDeactivationDate) {
    message = `Active ${canonicalDeactivationDate}`;
  } else if (isCanonicallyInactive !== isLocallyInactive) {
    message = 'Saving...';
  }

  return (
    <div className="active-container">
      <div className="toggle">
        <div className={isLocallyInactive ? 'inactive-status' : undefined}>Inactive</div>
        <div
          role="button"
          className={`pill ${isLocallyInactive ? '' : 'active-pill'} ${isLocallyInactive !== isCanonicallyInactive ? 'toggle-disabled' : undefined
            }`}
          onClick={onToggle}
        >
          <div className={`pip ${isLocallyInactive ? '' : 'active-pip'}`} />
        </div>
        <div className={isLocallyInactive ? undefined : 'active-status'}>Active</div>
      </div>
      <div className="active-container-deactivating">{message}</div>
    </div>
  );
}

export default function FirmUserList({
  users,
  invites,
  onInvite,
  onActivateDeactivate,
}: {
  users: User[];
  invites: UserInvite[] | null;
  onInvite: (email: string, type: UserType) => Promise<void>;
  onActivateDeactivate: (userId: string, activate: boolean) => Promise<void>;
}) {
  const invitesTableStyles = {
    email: getWidth(30),
    created: {
      ...getWidth(20),
      borderRightWidth: 0,
    },
    resend: getWidth(50),
  };

  const usersTableStyles = {
    name: getWidth(15),
    email: getWidth(15),
    created: getWidth(15),
    type: {
      ...getWidth(15),
      borderRightWidth: 0,
    },
    active: getWidth(40),
  };
  const userList = { nodes: users.map((user) => ({ ...user, id: user.id! })) };
  const inviteList = invites && { nodes: invites.map((invite) => ({ ...invite, id: invite.emailAddress! })) };
  const [showModal, setShowModal] = useState(false);
  const [activateModel, setActivateModel] = useState<ActivateModel | undefined>();

  const userSort = useSort(
    userList,
    {
      state: {
        sortKey: 'CREATED',
        reverse: true,
      },
    },
    {
      sortFns: {
        NAME: (array) => array.sort((a, b) => a.lastName.localeCompare(b.lastName)),
        CREATED: (array) => array.sort((a, b) => Date.parse(a.createdOn) - Date.parse(b.createdOn)),
      },
    }
  );
  const inviteSort = useSort(
    userList,
    {
      state: {
        sortKey: 'CREATED',
        reverse: true,
      },
    },
    {
      sortFns: {
        EMAIL: (array) => array.sort((a, b) => a.id.localeCompare(b.id)),
        CREATED: (array) =>
          array.sort(
            (a, b) => Date.parse(a.lastResent ?? a.firstSent) - Date.parse(b.lastResent ?? b.firstSent)
          ),
      },
    }
  );

  function canResend(item: TableNode) {
    var dateNum = Date.parse((item.lastResent ?? item.firstSent)?.toString() || '');

    if (dateNum) {
      var date = new Date(dateNum);
      date.setMinutes(date.getMinutes() + 5);
      return new Date().toISOString() > date.toISOString();
    }

    return true;
  }

  function futureDeactivationDate(deactivationDate: string | undefined) {
    if (!deactivationDate) return '';

    var dateNum = Date.parse(deactivationDate.toString());

    if (dateNum) {
      var date = new Date(dateNum);
      const inFuture = new Date().toISOString() < date.toISOString();

      if (inFuture) {
        return ` until ${date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}`;
      }
    }

    return '';
  }

  return (
    <>
      <div className={'d-grid gap-2 d-md-flex justify-content-md-end'}>
        <BootstrapButton onClick={() => setShowModal(true)}>
          <PersonPlus /> Invite User
        </BootstrapButton>
      </div>
      <InviteModal
        isShow={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={onInvite}
      />
      {invites && !!invites.length && (
        <>
          <h2 className="mt-3">Pending Invites</h2>
          <Table data={inviteList!} sort={inviteSort!} layout={{ custom: true }}>
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCellSort sortKey="EMAIL" style={invitesTableStyles.email}>
                      Email Address
                    </HeaderCellSort>
                    <HeaderCellSort sortKey="CREATED" style={invitesTableStyles.created}>
                      Date Invite Sent
                    </HeaderCellSort>
                    <HeaderCell style={invitesTableStyles.resend}></HeaderCell>
                  </HeaderRow>
                </Header>

                <Body>
                  {tableList.map((item) => (
                    <Row key={item.id} item={item}>
                      <Cell style={invitesTableStyles.email}>{item.id}</Cell>
                      <Cell style={invitesTableStyles.created}>
                        <span>
                          {new Date(
                            Date.parse((item.lastResent ?? item.firstSent)?.toString() || '')
                          ).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })}
                        </span>
                      </Cell>
                      <Cell style={invitesTableStyles.resend}>
                        {canResend(item) ? (
                          <span
                            className="btn-link p-0 cursor-pointer"
                            onClick={() => onInvite(item.id, item.type)}
                          >
                            Resend Invite
                          </span>
                        ) : (
                          <span>Please wait a few minutes before resending</span>
                        )}
                      </Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        </>
      )}
      <h2 className="mt-3">Users</h2>
      <ActivateModal
        model={activateModel}
        onClose={() => {
          setActivateModel(undefined);
        }}
        onSubmit={onActivateDeactivate}
      ></ActivateModal>
      <Table id='firm-user-table' data={userList} sort={userSort} layout={{ custom: true }}>
        {(tableList) => (
          <>
            <Header>
              <HeaderRow>
                <HeaderCellSort style={usersTableStyles.name} sortKey="NAME">
                  Name
                </HeaderCellSort>
                <HeaderCell style={usersTableStyles.email}>Email Address</HeaderCell>
                <HeaderCellSort style={usersTableStyles.created} sortKey="CREATED">
                  Date Joined
                </HeaderCellSort>
                <HeaderCell style={usersTableStyles.type}>User Type</HeaderCell>
                <HeaderCell style={usersTableStyles.active}></HeaderCell>
              </HeaderRow>
            </Header>

            <Body>
              {tableList.map((item) => (
                <Row key={item.id} item={item}>
                  <Cell style={usersTableStyles.name}>{`${item.lastName}, ${item.firstName}`}</Cell>
                  <Cell style={usersTableStyles.email}>
                    {item.contactInfo?.find(
                      (x: ContactInfo) =>
                        x.type === ContactInfoType.Email && x.category === ContactInfoCategory.Office
                    )?.value || 'Unknown Email'}
                  </Cell>
                  <Cell style={usersTableStyles.created}>
                    {new Date(Date.parse(item.createdOn?.toString() || '')).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                  </Cell>
                  <Cell style={usersTableStyles.type}>{item.type}</Cell>
                  <Cell style={usersTableStyles.active}>
                    <Toggle
                      isCanonicallyInactive={item.isInactive || futureDeactivationDate(item.deactivationDate)}
                      canonicalDeactivationDate={futureDeactivationDate(item.deactivationDate)}
                      onActivateDeactivate={() =>
                        onActivateDeactivate(
                          item.id,
                          item.isInactive || futureDeactivationDate(item.deactivationDate)
                        )
                      }
                    />
                  </Cell>
                </Row>
              ))}
            </Body>
          </>
        )}
      </Table>
    </>
  );
}
