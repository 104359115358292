import { Case, ExternalPartners, User } from "briefpoint-client";
import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { ReactComponent as BriefpointLogo } from "../../images/logo.svg";
import { ReactComponent as ClioLogo } from '../../images/Glyph_Clio Glyph Blue.svg';
import { ReactComponent as SmokeballLogo } from '../../images/Smokeball_logo_mark.svg';
import { ReactComponent as MyCaseLogo } from '../../images/MyCase.svg';

export function getPartnerLogo(partner?: ExternalPartners) {
  if (!partner) {
    return null;
  }

  switch (partner!) {
    case ExternalPartners.Clio:
      return <ClioLogo title="Clio" style={{ width: "16px", marginRight: "0.25em" }} />;
    case ExternalPartners.Smokeball:
      return <SmokeballLogo title="Smokeball" style={{ width: "16px", marginRight: "0.25em" }} />;
    case ExternalPartners.MyCase:
      return <MyCaseLogo title="MyCase" style={{ width: "16px", marginRight: "0.25em" }} />;
    default:
      return null;
  }
}

function ExternalPartnerMenu(
  { _case, user, externalAction, bpDefault }: {
    _case?: Case;
    user?: User;
    externalAction: React.Dispatch<React.SetStateAction<boolean>>;
    bpDefault: (e: React.MouseEvent) => void;
  },
) {
  return (
    <>
      {_case?.integration?.identifier || user?.externalConnection?.isActive
        ? (
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              Upload Document
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => externalAction(true)}>
                {getPartnerLogo(_case?.integration?.partner || user?.externalConnection?.partner)}{" "}
                From {_case?.integration?.partner || user?.externalConnection?.partner}
              </Dropdown.Item>
              <Dropdown.Item onClick={bpDefault}>
                <BriefpointLogo
                  style={{ width: "16px", marginRight: "0.25em" }}
                />{" "}
                From your computer
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )
        : (
          <Button variant="primary" size="sm" onClick={bpDefault}>
            Upload Document
          </Button>
        )}
    </>
  );
}

export default ExternalPartnerMenu;
