import { useCallback, useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { Case, CaseMetadata, DocumentSubType, type Jurisdiction, ServiceManner, CollectionItem } from "briefpoint-client";
import styles from "./ReviewPage.module.scss";
import { FirmClient } from "services/FirmService";
import { blankId, CaliforniaId } from "./SourceDocumentReview";
import CaseTypeSelect, { CaseTypeOption } from "components/CaseManagement/CaseTypeSelect";
import { XLg } from "react-bootstrap-icons";
import { formatGenericInfoName, genericInfoHandleChange, genericInfoHandleRemove, pleadCollectionChange, pleadCollectionRemove, pleadCollectionAdd } from "./utils";
import PleadingField from "./Components/PleadingField";
import Button from "components/Button";

function ReviewPageThreePd({
  _case,
  caseType,
  damages,
  incidentDate,
  incidentDescription,
  incidentLocation,
  incidentTime,
  jurisdictions,
  firm,
  genericInfo,
  injuries,
  agreements,
  timelineDetails,
  causesOfAction,
  setCaseType,
  setIncidentTime,
  setIncidentLocation,
  setCausesOfAction,
  setDamages,
  setGenericInfo,
  setInjuries,
  setAgreements,
  setIncidentDate,
  setIncidentDescription,
  setTimelineDetails,
}: {
  _case: Case | undefined;
  caseType: string | null | undefined;
  damages: string | null | undefined;
  genericInfo: { [key: string]: string } | null | undefined;
  injuries: Array<CollectionItem> | null;
  agreements: Array<CollectionItem> | null;
  incidentDate: string | null | undefined;
  incidentDescription: string | null | undefined;
  incidentLocation: string | null | undefined;
  incidentTime: string | null | undefined;
  timelineDetails: string | null | undefined;
  caseMetaData: CaseMetadata | undefined;
  jurisdictions: Jurisdiction[];
  firm: FirmClient | undefined;
  jurisdiction: Jurisdiction | undefined;
  causesOfAction: Array<CollectionItem> | null;
  documentSubType: DocumentSubType;
  setDocumentSubType: React.Dispatch<React.SetStateAction<DocumentSubType>>;
  setDateOfService: React.Dispatch<React.SetStateAction<string | undefined>>;
  methodOfService: ServiceManner | undefined;
  setCaseMetaData: React.Dispatch<React.SetStateAction<CaseMetadata | undefined>>;
  setIncidentTime: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  setIncidentLocation: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  setCausesOfAction: React.Dispatch<React.SetStateAction<Array<CollectionItem> | null>>;
  setCaseType: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  setDamages: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  setGenericInfo: React.Dispatch<React.SetStateAction<{ [key: string]: string } | null | undefined>>;
  setInjuries: React.Dispatch<React.SetStateAction<Array<CollectionItem> | null>>;
  setAgreements: React.Dispatch<React.SetStateAction<Array<CollectionItem> | null>>;
  setIncidentDate: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  setIncidentDescription: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  setTimelineDetails: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}) {

  const jurisdiction = useMemo(() => {
    if (!jurisdictions || !firm) {
      return undefined;
    }

    const isJsxBlank = _case?.jurisdiction === blankId;
    const firmJx = firm.offices?.find((x) =>
      x.jurisdictions?.length
    )?.jurisdictions![0]?.jurisdictionId ?? CaliforniaId;

    if (isJsxBlank) {
      return jurisdictions.find((j) => j.id === firmJx);
    } else {
      return jurisdictions?.find((j) => j.id === _case?.jurisdiction) ??
        jurisdictions?.find((j) => j.id === firmJx);
    }
  }, [jurisdictions, firm, _case?.jurisdiction]);

  const caseTypes = jurisdiction?.caseClasses?.filter((c) => c.subTypes)
    .flatMap((c) => [c.subTypes!]).flat();

  const options: CaseTypeOption[] = useMemo(
    () => {
      if (!caseTypes || !jurisdiction?.caseClasses) {
        return [];
      }
      let vs = caseTypes?.flatMap((v) => [
        {
          value: v.id!,
          label: v.shortName!,
          key: jurisdiction.caseClasses!.find((x) => x.id === v.caseClassId)
            ?.name ?? "",
        },
      ]);
      return vs;
    },
    [caseTypes, jurisdiction?.caseClasses],
  );

  function handleCaseTypeChange(caseType: string) {
    const matchedType = options.find((x) => x.value === caseType);
    if (matchedType) {
      setCaseType(matchedType.value);
    }
  }

  // Replace the default extracted values from the document if existing case details are available
  const setExistingCaseDetails = useCallback(async () => {
    if (!_case) return;

    const { damages, incidentDate, incidentTime, incidentLocation, incidentDescription, genericInfo, caseType, injuries, agreements, causesOfAction } = _case;

    if (damages) setDamages(damages);
    if (incidentDate) setIncidentDate(incidentDate);
    if (incidentTime) setIncidentTime(incidentTime);
    if (incidentLocation) setIncidentLocation(incidentLocation);
    if (incidentDescription) setIncidentDescription(incidentDescription);
    if (genericInfo) setGenericInfo(genericInfo);
    if (caseType) setCaseType(caseType);
    if (injuries && injuries.length > 0) setInjuries(injuries);
    if (agreements && agreements.length > 0) setAgreements(agreements);
    if (causesOfAction && causesOfAction.length > 0) setCausesOfAction(causesOfAction);

  }, [_case, setDamages, setIncidentDate, setIncidentTime, setCaseType, setGenericInfo, setInjuries, setIncidentDescription, setIncidentLocation, setAgreements, setCausesOfAction]);

  useEffect(() => {
    // Update form field states if there is existing '_case' information
    setExistingCaseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={styles.pendingSection}>
        <div className={styles.pendingSectionHeader}>
          <p>Case Info</p>
        </div>
        <div className={styles.pendingSectionFields}>
            <Form.Group>
              <div className={styles.allegationField}>
                <label>Case Type</label>
                <CaseTypeSelect
                  className="select"
                  options={options}
                  onChange={handleCaseTypeChange}
                  selected={caseType ?? ""}
                />
            </div>
            <PleadingField label="Damages Amount" setField={setDamages} pleadingValue={damages} />
          </Form.Group>
        </div>
      </div>
      {/* MAP OUT CoAs IF THEY EXIST */}
      {causesOfAction && causesOfAction.length > 0 && (
        <div className={styles.pendingSection}>
          <div className={styles.pendingSectionHeader}><p>Causes of Action</p></div>
          <div className={styles.pendingSectionFields}>

            {causesOfAction.map((item, index) => (
              <div className={`${styles.allegationField} ${styles.noPadding}`}>
                <Form.Group
                  key={`coa-${item.number}`}
                  controlId={`coa-formText${item.number}`}
                >
                  {//Eric: not sure why i need the +1 in the label here so it doesn't start with 0 here, but not for some of the other collections
                  }
                  <PleadingField label={`Cause of Action ${(item.number ? item.number : index) + 1}`} isTextArea pleadingCollectionIndex={index} mappedFieldHandler={pleadCollectionChange} pleadingValue={item.text} pleadingCollection={causesOfAction} setCollection={setCausesOfAction} />
                  <button
                    className={styles.pendingRemove}
                    onClick={() => pleadCollectionRemove(index, causesOfAction, setCausesOfAction)}
                  >
                    <XLg />
                  </button>
                </Form.Group>
              </div>
            ))}
                <Button
                  variant="outline-secondary"
                  onClick={() =>
                    pleadCollectionAdd(causesOfAction, setCausesOfAction)}
                >
                  + Add Another
                </Button>
          </div>
        </div>
      )}
      {/* MAP OUT INJURIES IF THEY EXIST */}
      {injuries && injuries.length > 0 && (
        <div className={styles.pendingSection}>
          <div className={styles.pendingSectionHeader}><p>Injuries</p></div>
          <div className={styles.pendingSectionFields}>
            {injuries !== null && injuries.length > 0
              && (
                <>
                  {injuries.map((item, index) => (
                    <div className={`${styles.allegationField} ${styles.noPadding}`}>
                      <Form.Group
                        key={`injury-${item.number}`}
                        controlId={`injury-formText${item.number}`}
                      >
                        <PleadingField label={`Injury ${item.number}`} isTextArea pleadingCollectionIndex={index} mappedFieldHandler={pleadCollectionChange} pleadingValue={item.text} pleadingCollection={injuries} setCollection={setInjuries} />
                        <button
                          className={styles.pendingRemove}
                          onClick={() => pleadCollectionRemove(index, injuries, setInjuries)}
                        >
                          <XLg />
                        </button>
                      </Form.Group>
                    </div>
                  ))}
                <Button
                  variant="outline-secondary"
                  onClick={() =>
                    pleadCollectionAdd(injuries, setInjuries)}
                >
                  + Add Another
                </Button>
                </>
              )}
          </div>
        </div>
      )}
      {/* MAP OUT AGREEMENTS IF THEY EXIST */}
      {agreements && agreements.length > 0 && (
        <div className={styles.pendingSection}>
          <div className={styles.pendingSectionHeader}><p>Agreements</p></div>
          <div className={styles.pendingSectionFields}>
            {agreements !== null && agreements.length > 0
              && (
                <>
                  {agreements.map((item, index) => (
                    <div className={`${styles.allegationField} ${styles.noPadding}`}>
                      <Form.Group
                        key={`agreement-${item.number}`}
                        controlId={`agreement-formText${item.number}`}
                      >
                        <PleadingField label={`Agreement ${item.number}`} isTextArea pleadingCollectionIndex={index} mappedFieldHandler={pleadCollectionChange} pleadingValue={item.text} pleadingCollection={agreements} setCollection={setAgreements} />
                        <button
                          className={styles.pendingRemove}
                          onClick={() => pleadCollectionRemove(index, agreements, setAgreements)}
                        >
                          <XLg />
                        </button>
                      </Form.Group>
                    </div>
                  ))}
                    <Button variant="outline-secondary" onClick={() => pleadCollectionAdd(agreements, setAgreements)}>+ Add Another</Button>
                </>
              )}
          </div>
        </div>
      )}
      {genericInfo && (
        <div className={styles.pendingSection}>
          <div className={styles.pendingSectionHeader}><p>Other Details</p></div>
          <div className={styles.pendingSectionFields}>
            {genericInfo && Object.keys(genericInfo).length > 0
              && (
                <>
                  {Object.entries(genericInfo)
                    /* Sort generic info by their name */
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                    .map(([key, value], index) => (
                      <div className={`${styles.allegationField} ${styles.noPadding}`}>
                        <Form.Group
                          key={`${key}-${index}`}
                          controlId={`formText${index}`}
                        >
                          <div className="flex-grow-1">
                            <PleadingField label={formatGenericInfoName(key)} isTextArea genericKey={key} genericInfoObj={genericInfo} setGenericInfo={setGenericInfo} pleadingGenericInfoHandler={genericInfoHandleChange} pleadingValue={value} />
                          </div>
                          <button className={styles.pendingRemove} onClick={() => genericInfoHandleRemove(key, genericInfo, setGenericInfo)}><XLg /></button>
                        </Form.Group>
                      </div>
                    ))}
                </>
              )}
          </div>
        </div>
      )}
      <div className={styles.pendingSection}>
        <div className={styles.pendingSectionHeader}>
          <p>Incident Details</p>
        </div>
        <div className={styles.pendingSectionFields}>
          <Form.Group>
            <PleadingField label="Incident Date" setField={setIncidentDate} pleadingValue={incidentDate} />
            <PleadingField isTextArea label="Incident Location" setField={setIncidentLocation} pleadingValue={incidentLocation} />
            <PleadingField label="Incident Time" setField={setIncidentTime} pleadingValue={incidentTime} />
            <PleadingField isTextArea label="Incident Description" setField={setIncidentDescription} pleadingValue={incidentDescription} />
          </Form.Group>
        </div>
      </div>
      <div className={styles.pendingSection}>
        <div className={styles.pendingSectionHeader}>
          <p>Timeline</p>
        </div>
        <div className={styles.pendingSectionFields}>
          <Form.Group>
            <PleadingField isTextArea label="Details" setField={setTimelineDetails} pleadingValue={timelineDetails} />
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

export default ReviewPageThreePd;
