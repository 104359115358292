import { SubscriptionPlan, UserType } from 'briefpoint-client';
import Help from 'components/Help';
import { Modal } from 'components/Modals';
import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import validator from 'validator';
import './InviteModal.scss';

interface Props {
  isShow: boolean;
  onClose: () => void;
  onSubmit: (email: string, type: UserType) => Promise<void>;
}

export default function InviteModal({ isShow, onClose, onSubmit }: Props) {
  const { firm } = useAuth()!;
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [type, setType] = useState(UserType.Attorney);
  const [emailValid, setEmailValid] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const onConfirm = async () => {
    await handleClick();
  };

  function handleClose() {
    onClose();
  }

  async function handleClick() {
    if (!email || !validator.isEmail(email)) {
      setEmailValid(false);
      return;
    }
    setEmailValid(true);
    setIsSaving(true);
    await onSubmit(email, type);
    onClose();
    setIsSaving(false);
  }

  return (
    <Modal
      show={isShow}
      onClose={handleClose}
      onConfirm={onConfirm}
      title={'Invite User to Firm'}
      confirmText={'Send Invite'}
      showCancelButton={false}
      showConfirmButton={true}
      contentClassName="invite-modal-content"
      confirming={isSaving}
    >
      <div className="modal-instructions">
        Enter the email address of the user you'd like to invite below. <b>Please Note: </b>they must use this
        email address when they register for Briefpoint.
        {firm!.billingInfo.subscriptionPlan === SubscriptionPlan.Monthly
          ? " They will be added to your firm's monthly subscription starting when they pickup their invite."
          : ''}
        {firm!.billingInfo.subscriptionPlan === SubscriptionPlan.YearlyBundle
          ? " They will be added to your firm's yearly subscription and charged a prorated amount if your firm has used up the initial seat allowance."
          : ''}
        {firm!.billingInfo.subscriptionPlan === SubscriptionPlan.Yearly
          ? " They will be added to your firm's yearly subscription and charged a prorated amount based on the end of the current billing cycle."
          : ''}
      </div>
      <label htmlFor="email">Email Address</label>
      <Form.Control
        required
        placeholder={'someone@yourlawfirm.com'}
        onChange={(event) => setEmail(event.currentTarget.value)}
        isInvalid={!emailValid}
        id="email"
      />

      <br />
      <label>Type of User</label>

      <br />
      <Form.Check
        inline
        type="radio"
        label="Attorney"
        name="user-type"
        id="user-type-attorney"
        checked={type === UserType.Attorney}
        onChange={() => setType(UserType.Attorney)}
      />
      <Form.Check
        inline
        type="radio"
        label="Paralegal"
        name="user-type"
        id="user-type-paralegal"
        checked={type === UserType.Paralegal}
        onChange={() => setType(UserType.Paralegal)}
        className="checkbox-user-type-paralegal"
      />
      <Help id="type-help" text="Paralegals can only do work on behalf of other subscribing Attorneys." />
    </Modal>
  );
}
