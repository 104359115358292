
import { Modal } from 'components/Modals';
import { FileEarmark, CheckLg } from 'react-bootstrap-icons';
import "./LinkandCodeModal.scss"
import { useAuth } from 'hooks/useAuth';
import { useClientApi } from 'hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import { Client, CredentialResult, User } from 'briefpoint-client';

interface Props {
  isShow: boolean;
  onClose: (success: boolean) => void;
  clientId: string | undefined;
  credential?: CredentialResult;
}

export default function LinkandCodeModal({ isShow, onClose, clientId, credential}: Props) {
  const { user } = useAuth()!;
  const clientApi = useClientApi();
  const [client, setClient] = useState<Client | undefined>();
  const [copiedContent, setCopiedContent] = useState<string | null>(null);

  const loadClient = useCallback(async (user:User,clientId: string) => {
    if (clientId && user) {
      const client = await clientApi.clientGet({ firmId: user.firmId!, id: clientId });
      setClient(client);
    } 
  }, [clientApi]);

  useEffect(() => {
    if(user && clientId){
      loadClient(user, clientId);
    }
  }, [clientId, loadClient, user]);

  useEffect(() => {
    if (copiedContent !== null) {
      const timer = setTimeout(() => {
        setCopiedContent(null);
      }, 2000)
      return () => clearTimeout(timer);
    }
  }, [copiedContent]);

  function handleClose() {
    onClose(true)
  }

  const handleCopyClick = async (txt:string) => {
    try {
      await navigator.clipboard.writeText(txt);
      setCopiedContent(txt);
    } catch (err) {
    }
  };

  const fCode = credential?.credential?.firmCode ?? "";
  const cCode = credential?.credential?.passcode ?? "";
  const copyUrl = `${process.env.REACT_APP_BRIDGE_URL}?firmcode=${encodeURIComponent(credential?.credential?.firmCode ?? "")}&email=${encodeURIComponent(client?.contactEmail ?? "")}`;

  function ModalContent() {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <th>Link</th>
              <th>Client Code</th>
            </tr>
            <tr>
              <td className='table-left'>{`${process.env.REACT_APP_BRIDGE_URL}?`}
                <wbr/>{`firmcode=${encodeURIComponent(credential?.credential?.firmCode ?? "")}&`}
                <wbr/>{`email=${encodeURIComponent(client?.contactEmail ?? "")}`} 
                {copiedContent === copyUrl ? <CheckLg className="checkCopied" /> : <FileEarmark style={{marginLeft:"4px"}} onClick={ ()=> { handleCopyClick(copyUrl) }} />} 
                </td>
              <td className='table-right'>{`${cCode.substring(0,4)}-${cCode.substring(4)}`} 
                {copiedContent === credential?.credential?.passcode ? <CheckLg className="checkCopied" /> :  <FileEarmark style={{marginLeft:"4px"}} onClick={ ()=> { handleCopyClick(credential?.credential?.passcode ?? ""); }} />}
              </td>
            </tr>
            <tr>
              <th>Client Contact Email Address</th>
              <th>Firm Code</th>
            </tr>
            <tr>
              <td className='table-left'>{client?.contactEmail} 
                {copiedContent === client?.contactEmail ? <CheckLg className="checkCopied" /> : <FileEarmark style={{marginLeft:"4px"}} onClick={ ()=> { handleCopyClick(client?.contactEmail ?? ""); }} />}
              </td>
              <td className='table-right'>{`${fCode.substring(0,4)}-${fCode.substring(4)}`}
                {copiedContent === credential?.credential?.firmCode ? <CheckLg className="checkCopied" /> : <FileEarmark style={{marginLeft:"4px"}} onClick={ ()=> { handleCopyClick(credential?.credential?.firmCode ?? ""); }}/>}
              </td>
            </tr>
          </tbody>  
        </table>
      </div>
    );
  }


  return (
    <Modal
      show={isShow}
      onClose={handleClose}
      onConfirm={handleClose}
      title={`View Link and Code`}
      showCancelButton={false}
      showConfirmButton={false}
    >
      {ModalContent()}
    </Modal>
  );
}
